import React,{useState} from 'react';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import axios from 'axios';
import { IMAGES } from '../../../../constant/theme';
import { Link, useNavigate } from "react-router-dom";
import { connect, useDispatch } from 'react-redux';

import {loadingToggleAction, uploadAction } from '../../../../../../src/store/actions/AuthActions'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



//console.log(userDetails);
const Index = () => {
   const userDetails = JSON.parse(localStorage.getItem('userDetails'));
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const createdBy = userDetails.email;
   const userID = userDetails.userID;
     
   const [file, setFile] = useState([]);
   const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
     if (e.target.files) {
       setFile(e.target.files[0]);
       //console.log(file);
     }
   };
   function handleChange(e) 
   {
     if (!file) {
        return;
      }
      var formData = new FormData();
      formData.append("fileImage",file); 
      e.preventDefault();
    
      const path ="sessionupload";
      dispatch(uploadAction(file,path,userID,createdBy, navigate));

   }
    return(
        <>
            <ToastContainer position="top-center"></ToastContainer>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-1 col-lg-4">
                 
                    </div>
                    <div className="col-xl-9 col-lg-8">
                        <div className="card profile-card card-bx m-b30">
                            <div className="card-header">
                                <h6 className="title">Upload Session </h6>
                            </div>
                            <form className="profile-form" onSubmit={handleChange}>						
                                <div className="card-body">
                                    <div className="row">
                            
                                  
                                        <div className="col-sm-8 m-b30">
                                            <label className="form-label">Upload Faculty</label><a href='/downloadables/session.csv' target="_blank"  ><span style={{ color:'red' }}><em>Download Sample Data Format</em></span></a>
                                            <input className="form-control" type="file" id="file" name="file" onChange={handleFileChange}/>
                                        </div>
                                       
                                      
                                        
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <button className="btn btn-primary">Upload Now</button>
                                  
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Index;