import React,{useState, useEffect} from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import {  useDispatch } from 'react-redux';
import {loadingToggleAction, uploadActions } from '../../../../../../src/store/actions/AuthActions'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import  { fetchItemDegrees } from '../../Lists/DegreeClass';
import  { fetchItemGraduationYear } from '../../Lists/GraduationYear';
import Select from 'react-select';

const Index = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const baseUrl = process.env.REACT_APP_BASE_URL;  
    var  headers   = {'Content-Type': 'multipart/form-data', 'Authorization-Key': `${userDetails.apikey}`};
    const createdBy = userDetails.email;
    const userID = userDetails.userID;
      const [dataDepartment, setDataDepartment] =useState([]);
      const [dataCountry, setDataCountry] =useState([]);
      const [dataDegree, setDataDegree] =useState([]);
      const [dataState, setDataState] =useState([]);
      const [dataGraudationyear, setDataGraudationYear] =useState([]);
      const [dataFaculty, setDataFaculty] =useState([]);
      const [error, setError] = useState(null);
      const [selectDepartmentValue, setselectDepartmentValue] = useState();
      const [selectFacultyValue, setselectFacultyValue] = useState();
      const [selectGraduationYearValue, setselectGraduationYearValue] = useState();
      const [selectEntryYearValue, setselectEntryYearValue] = useState();
      const [selectDegreeValue, setselectDegreeValue] = useState();
      const [selectGenderValue, setselectGenderValue] = useState();
      const [selectStateValue, setselectStateValue] = useState();
      const [selectCountryValue, setselectCountryValue] = useState();
      const [fieldValue, setFieldValue]=useState();
  
    const [file, setFile] = useState([]);
    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        setFile(e.target.files[0]);
        console.log(file);
      }
    };
    function handleChange(e) 
    {
      if (!file) {
         return;
       }
       var formData = new FormData();
       formData.append("fileImage",file); 
       e.preventDefault();
     
       const path ="studentrecordsupload";
       const userData = {
             stateID : selectStateValue.value,
             countryID : selectCountryValue.value,
             degreeofclass : selectDegreeValue.value,
             graduationyear: selectGraduationYearValue.value,
             entryyear:selectEntryYearValue.value,
             facultycode: selectFacultyValue.value,
             departmentcode : selectDepartmentValue.value,
             userID: userID,
             createdBy:createdBy,
             path: path,
             file:file
            };
     
           dispatch(uploadActions(userData, navigate));
 
    }

    const options4 = [
        { value: 'Male', label: 'Male' },
        { value: 'Female', label: 'Female' },
    ]
    
    const entryYear = [];
    const currentYear = new Date().getFullYear();
    
    for (let year = 1960; year <= currentYear; year++) {
        entryYear.push({ value: year, label: year });
    }
    
    const graduationYear = [];
    for (let year = 1960; year <= currentYear; year++) {
        graduationYear.push({ value: year, label: year });
    }
    
    const loadFaculty =()=>{
        axios.get(`${baseUrl}savefaculty/${userDetails.userID}`).then(response => 
          {
             const customHeadings = response.data.data.map(item=>({
                         "value": item.facultycode,
                         "label": item.name        
                      }))
                      setDataFaculty(customHeadings);
              }) .catch((error) => {
              if (error.response) {
                  console.log(error.response);
                  console.log("server responded");
              } else if (error.request) {
                  console.log("network error");
              } else {
                  console.log(error);
              }
              });
      }
 

    

const loadCountry =()=>{
    axios.get(`${baseUrl}savescountryzone/${userID}`).then(response => 
      {
        //console.log(response);
         const customHeadings = response.data.data.map(item=>({
                     "value": item.id,
                     "label": item.country        
                  }))
                  setDataCountry(customHeadings);
          }) .catch((error) => {
          if (error.response) {
              console.log(error.response);
              console.log("server responded");
          } else if (error.request) {
              console.log("network error");
          } else {
              console.log(error);
          }
          });
  }
useEffect(()=>{
    loadCountry()
    loadFaculty()
  },[])
  const loadState =(countryID)=>{
    axios.get(`${baseUrl}savestate/0/0/${countryID}`).then(response => 
      {
         
         const customHeadings = response.data.data.map(item=>({
                     "value": item.id,
                     "label": item.name        
                  }))
                  setDataState(customHeadings);
          }) .catch((error) => {
          if (error.response) {
              console.log(error.response);
              console.log("server responded");
          } else if (error.request) {
              console.log("network error");
          } else {
              console.log(error);
          }
          });
  }
  const selectCountryOnChange = (selectedOption) => 
  {
    setFieldValue('country', selectedOption.value);
    setselectCountryValue(selectedOption);
    
    const countryID = selectedOption.value;
    loadState(countryID)
  }
  
  const selectStateOnChange = (selectedOption) => 
  {
    setFieldValue('country', selectedOption.value);
    setselectStateValue(selectedOption);
  }

  const selectFacultyOnChange = (selectedOption) => 
  {
    setFieldValue('facultyID', selectedOption.value);
    setselectFacultyValue(selectedOption);
  
    const facultyID = selectedOption.value;
     axios.get(`${baseUrl}savedepartment/departmentlist/${userDetails.userID}/${facultyID}`).then(response => 
      { 
                 const customHeadings = response.data.data.map(item=>({
                    "value":item.departmentcode,
                    "label": item.name,
                   
    }))
    setDataDepartment(customHeadings);
  
          }) .catch((error) => {
          if (error.response) {
             
              console.log("server responded");
          } else if (error.request) {
              console.log("network error");
          } else {
              console.log(error);
          }
          });
  };
  const selectDepartmentOnChange = (selectedOption) => 
  {
    setFieldValue('department', selectedOption.value);
    setselectDepartmentValue(selectedOption);
  }
  
const selectGraduationYearOnChange = (selectedOption) => 
{
  setFieldValue('graduationyear', selectedOption.value);
  setselectGraduationYearValue(selectedOption);
};

const selectGenderOnChange = (selectedOption) => 
{
  setFieldValue('title', selectedOption.value);
  setselectGenderValue(selectedOption);
};

const selectDegreeOnChange = (selectedOption) => 
{
  setFieldValue('degree', selectedOption.value);
  setselectDegreeValue(selectedOption);
};
const selectEntryYearOnChange = (selectedOption) => 
{
  setFieldValue('entryyear', selectedOption.value);
  setselectEntryYearValue(selectedOption);
};

useEffect(()=>{
    const fetchDegreeData = async (userID) => {
        try 
        {
           const response = await fetchItemDegrees(userID);
           const customHeadings = response.data.map(item=>({
            "value": item.id,
            "label":item.name      
         }))
         setDataDegree(customHeadings);
        } catch (error) {
          setError(error.message);
        }
      };
      fetchDegreeData(userID)
 },[userID])


 useEffect(()=>{
    const fetchGraduationData = async (userID) => {
        try 
        {
           const response = await fetchItemGraduationYear(userID);
           const customHeadings = response.data.map(item=>({
            "value": item.year,
            "label":item.year      
         }))
         setDataGraudationYear(customHeadings);
        } catch (error) {
          setError(error.message);
        }
      };
      fetchGraduationData(userID)
 },[userID])

    return(
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-1 col-lg-4">
                 
                    </div>
                    <div className="col-xl-9 col-lg-8">
                        <div className="card profile-card card-bx m-b30">
                            <div className="card-header">
                                <h6 className="title">Upload Student Information </h6>
                            </div>
                            <form className="profile-form" onSubmit={handleChange}>
                                <div className="card-body">
                                    <div className="row">

                                    <div className="col-sm-4 m-b30">
                                            <label className="form-label">Country</label>
                                            <Select options={dataCountry} value={selectCountryValue}  className="custom-react-select" onChange={selectCountryOnChange} 
                                               placeholder="Country"
                                                isSearchable={true}
                                            />
                                              {/* <Select placeholder='Country ' name="searchValueCountry"  isSearchable={true} value={selectCountryValue} onChange={selectCountryOnChange} options={dataCountry} required  /> */}
                                        </div>
                                        <div className="col-sm-4 m-b30">
                                            <label className="form-label">State</label>
                                            <Select options={dataState}  className="custom-react-select"  onChange={selectStateOnChange} value={selectStateValue}
                                                placeholder="States"
                                                isSearchable={true} required
                                            />
                                        </div>
                                       
                                        
                                         <div className="col-sm-4 m-b30">
                                            <label className="form-label">Faculty</label>
                                            <Select placeholder='Faculty ' name="searchValueFaculty"  isSearchable={true} value={selectFacultyValue} onChange={selectFacultyOnChange} options={dataFaculty} required  />
                                        </div>
                                        <div className="col-sm-4 m-b30">
                                            <label className="form-label">Department</label>
                                            <Select options={dataDepartment} name="departmentID" id='departmentID' onChange={selectDepartmentOnChange} value={selectDepartmentValue}  className="custom-react-select" 
                                             placeholder="Department" 
                                            isSearchable={true} required
                                        />
                                         </div>
                                         <div className="col-sm-4 m-b30">
                                                <label className="form-label">Year of Entry</label>
                                                <Select options={entryYear} value={selectEntryYearValue} onChange={selectEntryYearOnChange}  className="custom-react-select" 
                                                defaultValue={entryYear[0]}
                                                isSearchable={true} required
                                            />
                                            </div>
                                           <div className="col-sm-4 m-b30">
                                                <label className="form-label">Year of Graduation</label>
                                                <Select options={dataGraudationyear} value={selectGraduationYearValue} onChange={selectGraduationYearOnChange}  className="custom-react-select" 
                                                defaultValue={dataGraudationyear[0]}
                                                isSearchable={true} required
                                            />
                                            </div>
                                            <div className="col-sm-4 m-b30">
                                                <label className="form-label">Class of Degree</label>
                                                <Select options={dataDegree} value={selectDegreeValue} onChange={selectDegreeOnChange}  className="custom-react-select" 
                                                defaultValue={dataDegree[0]}
                                                isSearchable={true} required
                                            />
                                            </div>
                                  
                                        <div className="col-sm-8 m-b30">
                                            <label className="form-label">Upload Student Information</label><a href='/downloadables/studentrecord.csv' target="_blank"  ><span style={{ color:'red' }}><em>Download Sample Data Format</em></span></a>
                                            <input className="form-control" type="file" id="file" onChange={handleFileChange} />
                                        </div>
                                       
                                      
                                        
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <button className="btn btn-primary">Upload Now</button>
                                  
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Index;