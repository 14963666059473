import React, { Fragment, useState, useReducer, useEffect} from "react";
import PageTitle from "../../../../layouts/PageTitle";
import {
  Row,
  Col,
  Card,
  Table,
  Modal,
  Button,

} from "react-bootstrap";
import { Link } from "react-router-dom";
import Select from "react-select";
import { IMAGES } from '../../../../constant/theme';
import axios from "axios";
import {loadingToggleAction, saveAction } from '../../../../../../src/store/actions/AuthActions'
import { useNavigate } from "react-router-dom";
import { connect, useDispatch } from 'react-redux';
import ZonesList from '../../Reports/Views/CountryZone'
import  { fetchItems} from '../../Lists/Country';
import  { fetchZoneItems } from '../../Lists/Zones';

const examTopper = [{id:'111', color:'success', status:'Successful'}];



const init = false
const reducer = (state, active) =>{
	switch(active.type){
		case 'basicModal' :
			return { ...state, basicModal: !state.basicModal}
		case 'contentModal'	:
			return { ...state, contentModal: !state.contentModal}
		case 'modalCentered'	:
			return { ...state, modalCentered: !state.modalCentered}
		case 'modalWithTooltip'	:
			return { ...state, modalWithTooltip: !state.modalWithTooltip}		
		case 'gridInsideModal'	:
			return { ...state, gridInsideModal: !state.gridInsideModal}
		case 'largeModal'	:
			return { ...state, largeModal: !state.largeModal}
		case 'smallModal'	:
			return { ...state, smallModal: !state.smallModal}		
		default :	
			return state;
	}	
}

const Index = () => {
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const [itemsCountry, setItemsCountry] = useState([]);
    const [itemsZones, setItemsZones] = useState([]);
    const [selectCountryValue, setselectCountryValue] = useState();
    const [selectZoneValue, setselectZoneValue] = useState();
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const dispatches = useDispatch();
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [fieldValue, setFieldValue]=useState();
    const [userinfo, setUserInfo] =useState([]);
    const [checked, setChecked] = useState(examTopper);
    const [unchecked, setUnChecked] = useState(true);
    const [state ,dispatch] = useReducer(reducer, init);
    const [zoneData, setZoneData] =useState([]);
    const handleChecked = (id)=> {
          let temp = checked.map((data) => {
              if (id === data.id) {
                  return { ...data, inputchecked: !data.inputchecked };
              }
              return data;
          });
          setChecked(temp);
      };
      const handleCheckedAll = (value)=> {
          let temp = checked.map((data) => {          
              return { ...data, inputchecked: value };   
          });
          setChecked(temp);
          setUnChecked(!unchecked);
      };
  
    
 useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchItems();
        const customHeadings = response.data.map(item=>({
            "value": item.name,
            "label": item.name        
         }))
        setItemsCountry(customHeadings);
      } catch (errors) {
        setError(errors.message);
      }
    };

    fetchData();
  }, []); 

  // useEffect(() => {
  //   const fetchZoneData = async () => {
  //     try {
  //       const response = await fetchZoneItems();
  //       console.log(response);
  //       const customHeadings = response.data.map(item=>({
  //           "value": item.id,
  //           "label": item.name        
  //        }))
  //       setItemsZones(customHeadings);
  //     } catch (errors) {
  //       setError(errors.message);
  //     }
  //   };

  //   fetchZoneData();
  // }, []); 



  useEffect(() => {
    const fetchZone = async () => {
      try {
        const response = await fetchZoneItems();
        //console.log(response);
        const customHeadings = response.data.map(item=>({
            "value": item.id,
            "label": item.name        
         }))
         setZoneData(customHeadings);
      } catch (error) {
        setError(error.message);
      }
    };
    fetchZone();
  }, []); 
  
  

      const selectCountryOnChange = (selectedOption) => 
        {
          setFieldValue('country', selectedOption.value);
          setselectCountryValue(selectedOption);
        }
        

        const seletZoneOnChange = (selectedOption) => 
            {
              setFieldValue('country', selectedOption.value);
              setselectZoneValue(selectedOption);
            }
    function handleSubmit(e) 
    {
      e.preventDefault();
      const path ="savescountryzone";
      const userData ={
                   
                   name : selectCountryValue.value,
                   zone : selectZoneValue.value,
                   userID:userDetails.userID,
                   createdBy:userDetails.email,
         }
      dispatches(saveAction(userData,path,navigate));
    }




    
    return(
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-1 col-lg-4">
                 
                    </div>
                    <div className="col-xl-6 col-lg-4">
                        <div className="card profile-card card-bx m-b30">
                            <div className="card-header">
                                <h6 className="title">Create Country Zones </h6>
                            </div>
                            <form onSubmit={handleSubmit}> 
                                <div className="card-body">
                                    <div className="row">
                       
                                    <div className="col-sm-6 m-b30">
                                            <label className="form-label">Zones </label>
                                            <Select options={zoneData} value={selectZoneValue}  className="custom-react-select" onChange={seletZoneOnChange} 
                                                placeholder="Zone"
                                                isSearchable={true} required
                                            />                                        </div>
                                        <div className="col-sm-6 m-b30">
                                            <label className="form-label">Country </label>
                                            <Select options={itemsCountry} value={selectCountryValue}  className="custom-react-select" onChange={selectCountryOnChange} 
                                                placeholder="Country"
                                                isSearchable={true} required
                                            />
                                        </div>
                                   </div>
                                <div className="card-footer">
                                    <button className="btn btn-primary">Add Country Zone</button>
                                  
                                </div>
                                </div>                              
                            </form>

                        </div>
                    </div>

                     <ZonesList />
                    
                </div>


    </div>
    
        </>
    )
}
export default Index;