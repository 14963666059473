import React,{useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import Select from 'react-select';
import {useNavigate } from "react-router-dom";
import {useDispatch } from 'react-redux';
import axios from 'axios';
import {saveAction } from '../../../../../../src/store/actions/AuthActions'
import ProgrammeIndex from '../../Reports/Views/Programme/'

const Index = () => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const dispatches = useDispatch();
    const navigate = useNavigate();
    const [department, setDepartment] =useState([]);
    const [selectDepartmentValue,setselectDepartmentValue]=useState();
    const [dataFaculty, setDataFaculty] =useState([]);
    const [selectFacultyValue, setselectFacultyValue] = useState();
    const [fieldValue, setFieldValue]=useState();
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const headers   = {'Content-Type': 'multipart/form-data', 'Authorization-Key': `${userDetails.apikey}`};

   const loadFaculty =()=>{
    
    axios.get(`${baseUrl}savefaculty/${userDetails.userID}`,{headers}).then(response => 
      {
        
         const customHeadings = response.data.data.map(item=>({
                     "value": item.facultycode,
                     "label": item.name        
                  }))
                  setDataFaculty(customHeadings);
          }) .catch((error) => {
          if (error.response) {
              //console.log(error.response);
              //console.log("server responded");
          } else if (error.request) {
             // console.log("network error");
          } else {
              //console.log(error);
          }
          });
  }

  useEffect(()=>{
    loadFaculty()
  },[])

const selectFacultyOnChange = (selectedOption) => 
{
  setFieldValue('title', selectedOption.value);
  setselectFacultyValue(selectedOption);

  const facultyID = selectedOption.value;
   axios.get(`${baseUrl}savedepartment/departmentlist/${userDetails.userID}/${facultyID}`,{headers}).then(response => 
    {              
        //console.log(response);
               const customHeadings = response.data.data.map(item=>({
                  "value":item.departmentcode,
                  "label": item.name,
                 
                }))
                setDepartment(customHeadings);

        }) .catch((error) => {
        if (error.response) {
           
            console.log("server responded");
        } else if (error.request) {
            console.log("network error");
        } else {
            console.log(error);
        }
        });
};


const selectDepartmentOnChange = (selectedOption) => 
{
  setFieldValue('department', selectedOption.value);
  setselectDepartmentValue(selectedOption);
}


function handleSubmit(e) 
{
  e.preventDefault();
  const path ="saveprogramme";
  const userData ={
               departmentcode:selectDepartmentValue.value,
               facultycode:selectFacultyValue.value,
               name : e.target.programme.value,
               programmecode : e.target.programmecode.value,
               userID:userDetails.userID,
               createdBy:userDetails.email,
     }
     //console.log(userData);
  dispatches(saveAction(userData,path,navigate));
  //window.location.reload();

}

    return(
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-1 col-lg-4">
                 
                    </div>
                    <div className="col-xl-9 col-lg-8">
                        <div className="card profile-card card-bx m-b30">
                            <div className="card-header">
                                <h6 className="title">Create School Programme </h6>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-2 m-b30">
                                            <label className="form-label">Code </label>
                                            <input type="text" className="form-control" name="programmecode" id="programmecode" placeholder="Code" />
                                        </div>
                             
                                        <div className="col-sm-3 m-b30">
                                            <label className="form-label">Programme </label>
                                            <input type="text" className="form-control" name="programme" id="programme" placeholder="Computer Science" />
                                        </div>
                                        <div className="col-sm-3 m-b30">
                                            <label className="form-label">Faculty</label>
                                            <Select placeholder='Faculty ' name="searchValueFaculty"  isSearchable={true} value={selectFacultyValue} onChange={selectFacultyOnChange} options={dataFaculty} required  />
                                        </div>
                                        <div className="col-sm-4 m-b30">
                                            <label className="form-label">Department Name</label>
                                            <Select placeholder='Department ' name="searchValueDepartment"  isSearchable={true} value={selectDepartmentValue} onChange={selectDepartmentOnChange} options={department} required  />
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <button className="btn btn-success">Add Programme</button>
                                  
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <ProgrammeIndex />
            </div>
        </>
    )
}
export default Index;