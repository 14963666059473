import React, { useContext, useState } from "react";
import { useDispatch , useSelector } from 'react-redux';
/// React router dom
import { Link } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";
import { SVGICON } from "../../constant/theme";
import { navtoggle } from "../../../store/actions/AuthActions";
import { IMAGES } from "../../constant/theme";
import LOGO  from "../../../images/logo/logo_edit.png"

const NavHader = () => {
  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  const baseUrl = process.env.REACT_APP_BASE_URL_IMAGE;  
  const logo =userDetails.logo;
  //console.log(userDetails);
  const {  openMenuToggle } = useContext(
    ThemeContext
  );

  const dispatch = useDispatch();
  const sideMenu = useSelector(state => state.sideMenu);
  const handleToogle = () => {
    dispatch(navtoggle());
  };
  return (
    <div className="nav-header">
      <Link to="/dashboard" className="brand-logo"> 
      {logo ? (
         <img src={baseUrl + logo} height="40px" width="70px" style={{ margin: 20 }} />
    ) : (
      <img src={LOGO} height="40px" width="40px" style={{ margin: 20 }} />
    )}

<h6
  className="wrap-text"
  style={{
    wordWrap: 'break-word', // Break long words if necessary
    whiteSpace: 'normal',   // Allow wrapping to multiple lines
    lineHeight: '0.85',      // Adjust line height for better readability
    maxWidth: '100px',      // Set a width limit to force wrapping
  }}
>
  {userDetails.schoolName}
</h6>

      </Link>
       
      <div 
        className="nav-control"
        onClick={() => {          
          openMenuToggle();
          handleToogle()
        }}
      >
        <div className={`hamburger ${sideMenu ? "is-active" : ""}`}
          
        >
          <span className="line">
						{SVGICON.NavHeaderIcon}
					</span>        
        </div>
      </div>
    </div>
  );
};

export default NavHader;
