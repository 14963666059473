import React, { Fragment, useState, useReducer, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Table,
  Modal,
  Button,

} from "react-bootstrap";

import { Link } from "react-router-dom";
import axios from 'axios';
import {useNavigate } from "react-router-dom";
import {useDispatch } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';


const examTopper = [{id:'111', color:'success', status:'Successful'}]

const init = false
const reducer = (state, active) =>{
	switch(active.type){
		case 'basicModal' :
			return { ...state, basicModal: !state.basicModal}
		case 'smallModal'	:
			return { ...state, smallModal: !state.smallModal}		
		default :	
			return state;
	}	
}

const ApplicationIndex = () => {
  const dispatches = useDispatch();
  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_BASE_URL;  
  const [checked, setChecked] = useState(examTopper);
  const [unchecked, setUnChecked] = useState(true);
  const [state ,dispatch] = useReducer(reducer, init);
  const [error, setError] = useState(null);
  const [data, setData] =useState([]);
  const [name, setName] =useState([]);
  const [amount, setAmount] =useState([]);
  const [ID, setID] =useState([]);
  const [fieldValue, setFieldValue]=useState();
  const [dataZones, setZones]=useState([]);
  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  const [selectSessionValue, setselectSessionValue] = useState();
  const userID = userDetails.userID;
  const headers   = {'Content-Type': 'application/json', 'Authorization-Key': `${userDetails.apikey}`};

  const handleChecked = (id)=> {
        let temp = checked.map((data) => {
            if (id === data.id) {
                return { ...data, inputchecked: !data.inputchecked };
            }
            return data;
        });
        setChecked(temp);
    };
    const handleCheckedAll = (value)=> {
        let temp = checked.map((data) => {          
            return { ...data, inputchecked: value };   
        });
        setChecked(temp);
        setUnChecked(!unchecked);
    };


    useEffect(()=>{
       listApplication(userID)
   },[])
 
   const listApplication = async (userID) => {
    try {  
         axios.get(`${baseUrl}gettranscripts/${userID}`).then(response =>
         {
              console.log(response);
              if(response.data.statuscode===200)
              {
                setData(response.data.data);
              }
              else 
              {
                throw new Error('Network response was not ok');
              }
         }).catch((error) => {
          if (error.response) {
              //console.log(error.response);
              //console.log("server responded");
          } else if (error.request) {
              //console.log("network error");
          } else {
              //console.log(error);
          }
          });
       
      } catch (error) {
        throw new Error(`Error fetching items: ${error.message}`);
      }
  };


 return (
    <Fragment>
      <ToastContainer position="top-right"></ToastContainer>
      <div className="container-fluid">
        <Row>
         
          <Col lg={12}>
            <Card>
              <Card.Header>
                <Card.Title>List of Applications</Card.Title>
              </Card.Header>
              <Card.Body>
              <Table responsive striped security='1'> 
                  <thead>
                    <tr>
                   
                      <th><strong>NO.</strong></th>
                      <th><strong>StudentId</strong></th>
                      <th><strong>Name</strong></th>
                      <th><strong>Amount</strong></th>
                      <th><strong>Destination</strong></th>
                      <th><strong>Email</strong></th>
                      <th><strong>Phone</strong></th>
                      <th><strong>Country</strong></th>
             
                      <th><strong>TransactionID</strong></th>
                      <th><strong>CreatedDate</strong></th>
                      
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item, index)=>(
                      <tr key={index}>
                        
                        
						            <td>{++index}</td>
                        <td>{item.studentId}</td>
                        <td>{item.applicantName}</td>
                        <td>{item.amount}</td>
                        <td>{item.destination}</td>
                        <td>{item.email}</td>
                        <td>{item.recipientPhone}</td>
                        <td>{item.country}</td>
                       
                        <td>{item.transactionId}</td>
                        <td>{item.createdDate}</td>
                       
                      </tr>
                    ))}
                    
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>     
        </Row>
      </div>    
    </Fragment>
  );

};

export default ApplicationIndex;
