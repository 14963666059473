import React,{useState, useEffect, useMemo} from 'react';
import {Link} from 'react-router-dom';
import Select from 'react-select';
import { IMAGES } from '../../../../constant/theme';
import {useNavigate } from "react-router-dom";
import {useDispatch } from 'react-redux';
import axios from 'axios';
import { saveActions, saveAction, getServiceAction } from '../../../../../../src/store/actions/AuthActions'
import  { fetchItems} from '../../Lists/Country';
import  { fetchItemState} from '../../Lists/State';
//import { fetchItems } from './api';
const inputName = [
    { label:'School Name', value:'Federal University Of Technology Akure' },
 
];

const Index = () => {
    const [itemsCountry, setItemsCountry] = useState([]);
    const [error, setError] = useState(null);
    const [dataCountry, setDataCountry] = useState([]);
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const dispatches = useDispatch();
    const navigate = useNavigate();
    const [userinfo, setUserInfo] =useState([]);
    const [logopath, setFilelogopath] = useState();
    const [selectCountryValue, setselectCountryValue] = useState();
    const [selectStateValue, setselectStateValue] = useState();
    const [fieldValue, setFieldValue]=useState();
    const [dataState, setDataState] = useState([]);
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));


   const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
        setFilelogopath(e.target.files[0]);
        console.log(logopath);
    }
  };
 function handleSubmit(e) 
 {
    e.preventDefault();
    //console.log(baseUrl);
    if (!logopath) {
        return;
      }
      var formData = new FormData();
      formData.append("fileImage",logopath); 
   

  const path ="changelogo";
  const userData ={
               logopath:logopath,
               userID:userDetails.userID,
               createdBy:userDetails.email,
     }
    dispatches(saveActions(userData,path,navigate));
    //window.location.reload();
 }



    return(
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-3 col-lg-4">
                        <div className="clearfix">
                            <div className="card card-bx profile-card author-profile m-b30">
                                
                            
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-8">
                        <div className="card profile-card card-bx m-b30">
                            <div className="card-header">
                                <h6 className="title">Change School Logo </h6>
                            </div>
                            <form onSubmit={handleSubmit}>
                               <div className="card-body">
                                    <div className="p-5">
                                        <div className="author-profile">
                                            <div className="author-media">
                                                 {logopath &&  <img src={logopath} alt="" /> }  {!logopath && <img src={IMAGES.User} alt="" /> }
                                              
                                                <div className="upload-link" title="" data-toggle="tooltip" data-placement="right" data-original-title="update">
                                                    <input type="file" onChange={handleFileChange} id='logopath' name="logopath"  className="update-flie" required />
                                                    <i className="fa fa-camera"></i>
                                                </div>
                                                
                                              
                                            </div>
                                            <div className="author-info">
                                                <h6 className="title">Upload School Logo</h6>
                                             
                                            </div>
                                        </div>
                                        <div className="card-footer">
                                            <button className="btn btn-primary">Change Logo</button>
                                        </div>
                                    </div>
                                  
                                </div>
                              
                               
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Index;