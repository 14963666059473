import React,{useState, useEffect} from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import {  useDispatch } from 'react-redux';
import {loadingToggleAction, uploadActions } from '../../../../../../src/store/actions/AuthActions'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import  { fetchItemsSession } from '../../Lists/Session';
import  { fetchItemDepartment  } from '../../Lists/Department';
const Index = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const baseUrl = process.env.REACT_APP_BASE_URL;  
    var  headers   = {'Content-Type': 'multipart/form-data', 'Authorization-Key': `${userDetails.apikey}`};
    const createdBy = userDetails.email;
    const userID = userDetails.userID;
    const [datasemester, setDataSemester]=useState([]);
    const [datalevel, setDataLevel]=useState([]);
    const [dataSession, setDataSession] =useState([]);
    const [dataDepartment, setDataDepartment] =useState([]);
    const [selectSessionValue, setselectSessionValue] = useState();
    const [selectDepartmentValue, setselectDepartmentValue] = useState();
    const [selectSemesterValue, setselectSemesterValue] = useState();
    const [selectLevelValue, setselectLevelValue] = useState();
    const [fieldValue, setFieldValue]=useState();
    const [error, setError] = useState(null);


    const [file, setFile] = useState([]);
    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        setFile(e.target.files[0]);
     
      }
    };
    function handleChange(e) 
    {
      if (!file) {
         return;
       }
       var formData = new FormData();
       formData.append("fileImage",file); 
       e.preventDefault();
     
       const path ="curriculumsupload";

       const userData = {
             session : selectSessionValue.value,
             semester : selectSemesterValue.value,
             level : selectLevelValue.value,
             departmentcode : selectDepartmentValue.value,
             userID: userID,
             createdBy:createdBy,
             path: path,
             file:file
            };
     
           dispatch(uploadActions(userData, navigate));
    }

    const selectSemesterOnChange = (selectedOption) => 
        {
          setFieldValue('semester', selectedOption.value);
          setselectSemesterValue(selectedOption);
        };
        
        const selectLevelOnChange = (selectedOption) => 
        {
          setFieldValue('level', selectedOption.value);
          setselectLevelValue(selectedOption);
        };
        const selectSessioneOnChange = (selectedOption) => 
        {
          setFieldValue('session', selectedOption.value);
          setselectSessionValue(selectedOption);
          let session = selectedOption.value;
                loadsemester(session)
                loadLevel(session)
        };
        const selectDepartmentOnChange = (selectedOption) => 
        {
          setFieldValue('department', selectedOption.value);
          setselectDepartmentValue(selectedOption);
        };


        const loadLevel =(session)=>{
            axios.get(`${baseUrl}savelevel/${session}/${userDetails.userID}`).then(response => 
              {
                
                 const customHeadings = response.data.data.map(item=>({
                             "value": item.name,
                             "label": item.name        
                          }))
                          setDataLevel(customHeadings);
                  }) .catch((error) => {
                  if (error.response) {
                      console.log(error.response);
                      console.log("server responded");
                  } else if (error.request) {
                      console.log("network error");
                  } else {
                      console.log(error);
                  }
                  });
          }
          const loadsemester =(session)=>{
            axios.get(`${baseUrl}savesemester/${session}/${userDetails.userID}`).then(response => 
                {
                 
                   const customHeadings = response.data.data.map(item=>({
                               "value": item.id,
                               "label": item.name        
                            }))
                            setDataSemester(customHeadings);
                    }) .catch((error) => {
                    if (error.response) {
                        console.log(error.response);
                        console.log("server responded");
                    } else if (error.request) {
                        console.log("network error");
                    } else {
                        console.log(error);
                    }
                    });
        
        
          }
       
  useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await fetchItemsSession();
            //console.log(response);
            const customHeadings = response.data.map(item=>({
                "value": item.id,
                "label": item.name        
             }))
            setDataSession(customHeadings);
          } catch (error) {
            setError(error.message);
          }
        };
    
        fetchData();
      }, []); 
    

       useEffect(()=>{
              const fetchDepartmentData = async (userID) => {
                  try 
                  {
                     const response = await fetchItemDepartment(userID);
                     //console.log(response.data)
                     const customHeadings = response.data.map(item=>({
                      "value": item.departmentcode,
                      "label":item.department      
                   }))
                   setDataDepartment(customHeadings);
                  } catch (error) {
                    setError(error.message);
                  }
                };
                fetchDepartmentData(userID)
           },[userID])
      
    return(
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-1 col-lg-4">
                 
                    </div>
                    <div className="col-xl-9 col-lg-8">
                        <div className="card profile-card card-bx m-b30">
                            <div className="card-header">
                                <h6 className="title">Upload Curriculum </h6>
                            </div>
                            <form className="profile-form" onSubmit={handleChange}>
                                <div className="card-body">
                                    <div className="row">
                                       <div className="col-sm-4 m-b30">
                                            <label className="form-label">Session </label>
                                            <Select options={dataSession} id="semester" name="semester" value={selectSessionValue} onChange={selectSessioneOnChange}  className="custom-react-select" 
                                            placeholder ="Session"
                                            isSearchable={true} required
                                        />
                                         </div>

                                        <div className="col-sm-4 m-b30">
                                            <label className="form-label">Semester </label>
                                            <Select options={datasemester} id="semester" name="semester" value={selectSemesterValue} onChange={selectSemesterOnChange}  className="custom-react-select" 
                                            placeholder ="Semester"
                                            isSearchable={true} required
                                        />
                                         </div>

                                           <div className="col-sm-4 m-b30">
                                            <label className="form-label">Level </label>
                                            <Select options={datalevel} id="level" name="level" value={selectLevelValue} onChange={selectLevelOnChange}  className="custom-react-select" 
                                             placeholder="Level"
                                            isSearchable={true} required
                                        />
                                        </div>
                                        <div className="col-sm-6 m-b30">
                                            <label className="form-label">Department </label>
                                            <Select options={dataDepartment} id="departmentcode" name="departmentcode" value={selectDepartmentValue} onChange={selectDepartmentOnChange}  className="custom-react-select" 
                                             placeholder="Department"
                                            isSearchable={true} required
                                        />
                                        </div>

                                  
                                        <div className="col-sm-6 m-b30">
                                            <label className="form-label">Upload Courses</label><a href='/downloadables/curriculum.csv' target="_blank"  ><span style={{ color:'red' }}><em>Download Sample Data Format</em></span></a>
                                            <input className="form-control" type="file" id="file" onChange={handleFileChange} />
                                        </div>
                                       
                                      
                                        
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <button className="btn btn-primary">Upload Now</button>
                                  
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Index;