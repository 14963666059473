import React, { Fragment, useState, useReducer } from "react";
import PageTitle from "../../../../layouts/PageTitle";
import {
  Row,
  Card,
  Table,
  Modal,
  Button,

} from "react-bootstrap";
import { Link } from "react-router-dom";
import Select from "react-select";
import {useNavigate } from "react-router-dom";
import {useDispatch } from 'react-redux';
import {saveAction } from '../../../../../../src/store/actions/AuthActions'
import GradeIndex from '../../Reports/Views/Grade';


const examTopper = [
  {id:'111', color:'success', status:'Successful'},
];

const sidebarLink = [
  {to:'/addGrade', title:'Add Grade'},
  {to:'/addScores', title:'Add Scores'},

 
];
const options3 = [
    { value: 'A', label: 'A' },
    { value: 'B', label: 'B' },
    { value: 'C', label: 'C' },
    { value: 'D', label: 'D' },
    { value: 'E', label: 'E' },
    { value: 'F', label: 'F' }
]

const init = false
const reducer = (state, active) =>{
	switch(active.type){
		case 'basicModal' :
			return { ...state, basicModal: !state.basicModal}
		case 'contentModal'	:
			return { ...state, contentModal: !state.contentModal}
		case 'modalCentered'	:
			return { ...state, modalCentered: !state.modalCentered}
		case 'modalWithTooltip'	:
			return { ...state, modalWithTooltip: !state.modalWithTooltip}		
		case 'gridInsideModal'	:
			return { ...state, gridInsideModal: !state.gridInsideModal}
		case 'largeModal'	:
			return { ...state, largeModal: !state.largeModal}
		case 'smallModal'	:
			return { ...state, smallModal: !state.smallModal}		
		default :	
			return state;
	}	
}

const Index = () => {
    const [activeLink ,setActiveLink] = useState(0);
    const [checked, setChecked] = useState(examTopper);
    const [unchecked, setUnChecked] = useState(true);
    const [state ,dispatch] = useReducer(reducer, init);
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const dispatches = useDispatch();
    const navigate = useNavigate();
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const [fieldValue, setFieldValue]=useState();
    const [selectGradeValue, setselectGradeValue] = useState();

    const handleChecked = (id)=> {
          let temp = checked.map((data) => {
              if (id === data.id) {
                  return { ...data, inputchecked: !data.inputchecked };
              }
              return data;
          });
          setChecked(temp);
      };
      const handleCheckedAll = (value)=> {
          let temp = checked.map((data) => {          
              return { ...data, inputchecked: value };   
          });
          setChecked(temp);
          setUnChecked(!unchecked);
      };

    const selectGradeOnChange = (selectedOption) => 
    {
      setFieldValue('grade', selectedOption.value);
      setselectGradeValue(selectedOption);
    };
    function handleSubmit(e) 
    {
        e.preventDefault();
        const path ="savegrade";
        const userData ={
                    grade: selectGradeValue.value,
                    minscore : e.target.minscore.value,
                    maxscore:e.target.maxscore.value,
                    point :e.target.point.value,
                    userID:userDetails.userID,
                    createdBy:userDetails.email,
            }
        dispatches(saveAction(userData,path,navigate));
    }


    return(
        <>
         <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-2 col-lg-4">
                       <div className="demo-right ">
                          <div className="demo-right-inner dlab-scroll " id="right-sidebar">
                            <h4 className="title">MENU</h4>
                            <ul className="navbar-nav" id="menu-bar">
                                {sidebarLink.map((item, ind)=>(
                                  <li key={ind}                        
                                  >
                                    <Link to={item.to}  smooth={true}
                                      className={`scroll ${ind === activeLink ? 'active' :  ''} `}
                                      activeClass="active"
                                      spy={true}
                                      onClick={()=>setActiveLink(ind)}
                                    > 
                                      {item.title}
                                    </Link>
                                  </li>
                                ))}
                            
                             </ul>	
                          </div>
                        </div>
                      </div>


                    <div className="col-xl-9 col-lg-1">
                        <div className="card profile-card card-bx m-b30">
                            <div className="card-header">
                                <h6 className="title">Create Grade System </h6>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className="card-body">
                                    <div className="row">
                        
                                      <div className="col-sm-2 m-b30">
                                            <label className="form-label">From </label>
                                            <input type="text" className="form-control" id="minscore" name="minscore" placeholder="70" required />
                                        </div>

                                        <div className="col-sm-2 m-b30">
                                            <label className="form-label">To </label>
                                            <input type="text" className="form-control" id="maxscore" name="maxscore" placeholder="100" required />
                                        </div>
                                        <div className="col-sm-2 m-b30">
                                            <label className="form-label">Point </label>
                                            <input type="text" className="form-control" placeholder="Point" id="point" name="point" required />
                                        </div>
                                    <div className="col-sm-2 m-b30">
                                        <label className="form-label">Grade</label>
                                        <Select options={options3}  className="custom-react-select" 
                                            onChange={selectGradeOnChange}
                                            value={selectGradeValue}
                                            defaultValue={options3[0]}
                                            isSearchable={true} required
                                        />
                                    </div> 
                                    
                                       
                                      
                                        
                                    </div>
                                <div className="card-footer">
                                    <button className="btn btn-primary">Add Grade</button>
                                  
                                </div>
                                </div>                              
                            </form>

                        </div> 
                        
                    </div>
                 <GradeIndex/>
                </div>






    </div>
    
        </>
    )
}
export default Index;