import axios from 'axios';
const baseUrl = process.env.REACT_APP_BASE_URL;  
const userDetails = JSON.parse(localStorage.getItem('userDetails'));

export const fetchItemsResult = async (matricno, level,semester,session,department) => {
    try {
        
     // var headers   = {'Content-Type': 'multipart/form-data', 'Authorization':'Token ' + `${userDetails.token}`};
      var headers   = {'Content-Type': 'multipart/form-data', 'Authorization-Key': `${userDetails.apikey}`};
      const userData= {matricno:matricno,levelID:level, semesterID:semester, sessionID:session,departmentcode:department,userID:userDetails.userID}

       axios.post(`${baseUrl}getresultofstudent/`,userData,{headers}).then(response =>
       {
            if(response.data.statuscode===200)
            {
               return response.data;
            }
            else 
            {
              throw new Error('Network response was not ok');
            }
       }).catch((error) => {
        if (error.response) {
            console.log(error.response);
            console.log("server responded");
        } else if (error.request) {
            console.log("network error");
        } else {
            console.log(error);
        }
        });
     
    } catch (error) {
      throw new Error(`Error fetching items: ${error.message}`);
    }
  };