import React,{useState, useEffect, useMemo} from 'react';
import {Link} from 'react-router-dom';
import Select from 'react-select';
import { IMAGES } from '../../../../constant/theme';
import {useNavigate } from "react-router-dom";
import {useDispatch } from 'react-redux';
import axios from 'axios';
import { saveAction, getServiceAction } from '../../../../../../src/store/actions/AuthActions'

const Index = () => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const dispatches = useDispatch();
    const navigate = useNavigate();
    const [userinfo, setUserInfo] =useState([]);
    const [dataCourier, setDataCounrier] =useState([]);
    const [selectCourierValue, setselectCourierValue] = useState();
    const [fieldValue, setFieldValue]=useState();
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const [dataCountry, setDataCountry] =useState([]);
    const [dataState, setDataState] =useState([]);
    const [selectStateValue, setselectStateValue] = useState();
    const [selectCountryValue, setselectCountryValue] = useState();
    const loadCourier =()=>{
        axios.get(`${baseUrl}savecourier/${userDetails.userID}`).then(response => 
          {
             const customHeadings = response.data.data.map(item=>({
                         "value": item.id,
                         "label": item.name        
                      }))
                      setDataCounrier(customHeadings);
              }) .catch((error) => {
              if (error.response) {
                  console.log(error.response);
                  console.log("server responded");
              } else if (error.request) {
                  console.log("network error");
              } else {
                  console.log(error);
              }
              });
      }
    
      useEffect(()=>{
        loadCourier()
      },[])
    
const loadCountry =()=>{
    axios.get(`${baseUrl}savescountryzone/${userDetails.userID}`).then(response => 
      {
        //console.log(response);
         const customHeadings = response.data.data.map(item=>({
                     "value": item.id,
                     "label": item.country        
                  }))
                  setDataCountry(customHeadings);
          }) .catch((error) => {
          if (error.response) {
              console.log(error.response);
              console.log("server responded");
          } else if (error.request) {
              console.log("network error");
          } else {
              console.log(error);
          }
          });
  }
useEffect(()=>{
    loadCountry()
    
  },[])
  const loadState =(countryID)=>{
    axios.get(`${baseUrl}savestate/0/0/${countryID}`).then(response => 
      {
         //console.log(response);
         const customHeadings = response.data.data.map(item=>({
                     "value": item.id,
                     "label": item.name        
                  }))
                  setDataState(customHeadings);
          }) .catch((error) => {
          if (error.response) {
              console.log(error.response);
              console.log("server responded");
          } else if (error.request) {
              console.log("network error");
          } else {
              console.log(error);
          }
          });
  }
    const selectCourierOnChange = (selectedOption) => 
    {
      setFieldValue('courierID', selectedOption.value);
      setselectCourierValue(selectedOption);
    };

    const selectCountryOnChange = (selectedOption) => 
    {
      setFieldValue('country', selectedOption.value);
      setselectCountryValue(selectedOption);
      
      const countryID = selectedOption.value;
      loadState(countryID)
    }
    
    const selectStateOnChange = (selectedOption) => 
    {
      setFieldValue('country', selectedOption.value);
      setselectStateValue(selectedOption);
    }
  
    function handleSubmit(e) 
    {
      e.preventDefault();
      const path ="saveshipping";
      const userData ={
                   courierID:selectCourierValue.value,
                   countryID : selectCountryValue.value,
                   location:e.target.address.value,
                   cost:e.target.cost.value,
                   userID:userDetails.userID,
                   createdBy:userDetails.email,
         }
      dispatches(saveAction(userData,path,navigate));
    }

    return(
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-1 col-lg-4">
                 
                    </div>
                    <div className="col-xl-9 col-lg-8">
                        <div className="card profile-card card-bx m-b30">
                            <div className="card-header">
                                <h6 className="title">Create Shipping  </h6>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className="card-body">
                                    <div className="row">
                                    <div className="col-sm-6 m-b30">
                                            <label className="form-label">Courier</label>
                                            <Select options={dataCourier} value={selectCourierValue}  className="custom-react-select" onChange={selectCourierOnChange} 
                                                placeholder="Courier" required
                                                isSearchable={true}
                                            />
                                              {/* <Select placeholder='Country ' name="searchValueCountry"  isSearchable={true} value={selectCountryValue} onChange={selectCountryOnChange} options={dataCountry} required  /> */}
                                        </div>
                              
                                        <div className="col-sm-6 m-b30">
                                            <label className="form-label">Country</label>
                                            <Select options={dataCountry} value={selectCountryValue}  className="custom-react-select" onChange={selectCountryOnChange} 
                                               placeholder="Country" required
                                                isSearchable={true}
                                            />
                                              {/* <Select placeholder='Country ' name="searchValueCountry"  isSearchable={true} value={selectCountryValue} onChange={selectCountryOnChange} options={dataCountry} required  /> */}
                                        </div>
                                        <div className="col-sm-6 m-b30">
                                            <label className="form-label">State</label>
                                            <Select options={dataState}  className="custom-react-select"  onChange={selectStateOnChange} value={selectStateValue}
                                                placeholder="States"
                                                isSearchable={true} required
                                            />
                                        </div>
                                        
                                        <div className="col-sm-6 m-b30">
                                            <label className="form-label">Cost </label>
                                            <input type="text" className="form-control" id="cost" name="cost" placeholder="Cost" required />
                                        </div>
                                         
                                        <div className="col-sm-12 m-b30">
                                            <label className="form-label">Location </label>
                                            <input type="text" className="form-control" id="address" name="address" placeholder="Location" required />
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <button className="btn btn-success">Add Shipping</button>
                                  
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Index;