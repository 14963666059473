import React,{useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import Select from 'react-select';
import {useNavigate } from "react-router-dom";
import {useDispatch } from 'react-redux';
import axios from 'axios';
import {saveAction } from '../../../../../../src/store/actions/AuthActions'
import CourseIndex from '../../Reports/Views/Course'
const options3 = [
    { value: '1', label: 'Core' },
    { value: '2', label: 'Elective' },
    { value: '3', label: 'Required' },
]

const Index = () => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const dispatches = useDispatch();
    const navigate = useNavigate();
    const [datalevel, setDataLevel]=useState([]);
    const [datasemester, setDataSemester]=useState([]);
    const [selectCourseStatusValue, setselectCourseStatusValue] = useState();
    const [selectSemesterValue, setselectSemesterValue] = useState();
    const [selectLevelValue, setselectLevelValue] = useState();
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const [fieldValue, setFieldValue]=useState();

    function handleSubmit(e) 
    {
        e.preventDefault();
        const path ="savescourses";
        const userData ={
                    coursetitle:e.target.coursetitle.value,
                    coursecode : e.target.coursecode.value,
                    description:e.target.description.value,
                    userID:userDetails.userID,
                    createdBy:userDetails.email,
            }
        dispatches(saveAction(userData,path,navigate));
    }

    const selectCourseStatusOnChange = (selectedOption) => 
    {
      setFieldValue('coursestatus', selectedOption.value);
      setselectCourseStatusValue(selectedOption);
    };
    
    const selectSemesterOnChange = (selectedOption) => 
    {
      setFieldValue('semester', selectedOption.value);
      setselectSemesterValue(selectedOption);
    };
    
    const selectLevelOnChange = (selectedOption) => 
    {
      setFieldValue('level', selectedOption.value);
      setselectLevelValue(selectedOption);
    };
    

    const loadLevel =()=>{
        axios.get(`${baseUrl}savelevel/${userDetails.userID}`).then(response => 
          {
            
             const customHeadings = response.data.data.map(item=>({
                         "value": item.id,
                         "label": item.name        
                      }))
                      setDataLevel(customHeadings);
              }) .catch((error) => {
              if (error.response) {
                  console.log(error.response);
                  console.log("server responded");
              } else if (error.request) {
                  console.log("network error");
              } else {
                  console.log(error);
              }
              });
      }

      const loadsemester =()=>{
        axios.get(`${baseUrl}savesemester/${userDetails.userID}`).then(response => 
          {
           
             const customHeadings = response.data.data.map(item=>({
                         "value": item.id,
                         "label": item.name        
                      }))
                      setDataSemester(customHeadings);
              }) .catch((error) => {
              if (error.response) {
                  console.log(error.response);
                  console.log("server responded");
              } else if (error.request) {
                  console.log("network error");
              } else {
                  console.log(error);
              }
              });
      }
    useEffect(()=>{
        loadLevel()
        loadsemester()
      },[])









    return(
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-1 col-lg-4">
                 
                    </div>
                    <div className="col-xl-9 col-lg-8">
                        <div className="card profile-card card-bx m-b30">
                            <div className="card-header">
                                <h6 className="title">Create School Courses </h6>
                            </div>
                            <form  onSubmit={handleSubmit}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-2 m-b30">
                                            <label className="form-label">Course Code </label>
                                            <input type="text" className="form-control" id="coursecode" name="coursecode" placeholder="STA101" required />
                                        </div>
                                        <div className="col-sm-10 m-b30">
                                            <label className="form-label">Course Title </label>
                                            <input type="text" className="form-control" name="coursetitle" id="coursetitle" placeholder="Introduction to Statisitics"  required/>
                                        </div>
                                        <div className="col-sm-12 m-b30">
                                            <label className="form-label">Description </label>
                                            <input type="text" className="form-control" id="description" name="description" placeholder="description" required />
                                        </div>
                                       
                                       
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <button className="btn btn-primary">Add Course</button>
                                  
                                </div>
                            </form>
                        </div>
                    </div>
                    <CourseIndex/>
                </div>
            </div>
        </>
    )
}
export default Index;