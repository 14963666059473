import React,{useState, useEffect} from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import {  useDispatch } from 'react-redux';
import {loadingToggleAction, uploadAction, uploadActions } from '../../../../../../src/store/actions/AuthActions'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import  { fetchItemsFaculty } from '../../Lists/Faculty';


const Index = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const createdBy = userDetails.email;
    const userID = userDetails.userID;
    const [dataFaculty, setDataFaculty] =useState([]);
    const [selectFacultyValue, setselectFacultyValue] = useState();
    const [fieldValue, setFieldValue]=useState();
    const [error, setError] = useState(null);
    const [file, setFile] = useState([]);
    
    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        setFile(e.target.files[0]);
        //console.log(file);
      }
    };
    
    function handleChange(e) 
    {
      if (!file) {
         return;
       }
       var formData = new FormData();
       formData.append("fileImage",file); 
       e.preventDefault();
       const path ="departmentupload";
      
       const userData = {
        facultycode:selectFacultyValue.value,
        userID: userID,
        createdBy:createdBy,
        path: 'departmentupload',
        file:file
       };
      
       dispatch(uploadActions(userData, navigate));
 
    }

    
    const selectFacultyOnChange = (selectedOption) => 
    {
      setFieldValue('faculty', selectedOption.value);
      setselectFacultyValue(selectedOption);
    }

    useEffect(()=>{
    const fetchFacultyData = async (userID) =>
    {
        try 
        {
          const response = await fetchItemsFaculty(userID);
          const customHeadings = response.data.map(item=>({
              "value": item.facultycode,
              "label": item.name        
           }))
           setDataFaculty(customHeadings);
        } catch (error) {
          setError(error.message);
        }
      };
      fetchFacultyData(userID)
    },[userID])
    
    
    return(
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-1 col-lg-4">
                 
                    </div>
                    <div className="col-xl-9 col-lg-8">
                        <div className="card profile-card card-bx m-b30">
                            <div className="card-header">
                                <h6 className="title">Upload Department </h6>
                            </div>
                            <form className="profile-form" onSubmit={handleChange}>
                                <div className="card-body">
                                    <div className="row">
                                    <div className="col-sm-8 m-b30">
                                            <label className="form-label">Faculty</label>
                                            <Select options={dataFaculty}  className="custom-react-select" 
                                                            placeholder="Faculty"
                                                            value={selectFacultyValue} onChange={selectFacultyOnChange}
                                                            isSearchable={true} />      
                                      </div>
                                  
                                        <div className="col-sm-8 m-b30">
                                           
                                            <label className="form-label">Upload Department</label> <a href='/downloadables/department.csv' target="_blank"  >  <span style={{ color:'red' }}><em>Download Sample Data Format</em></span></a>
                                            <input className="form-control" type="file" id="file" onChange={handleFileChange} />
                                        </div>
                                       
                                      
                                        
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <button className="btn btn-primary">Upload Now</button>
                                  
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Index;