import React, { Fragment, useState, useReducer, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Table,
  Modal,
  Button,

} from "react-bootstrap";

import { Link } from "react-router-dom";
import axios from 'axios';
import {useNavigate } from "react-router-dom";
import {useDispatch } from 'react-redux';
import {saveAction } from '../../../../../../store/actions/AuthActions'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import  { fetchItemsFaculty } from '../../../Lists/Faculty';
import Select from 'react-select';

const examTopper = [{id:'111', color:'success', status:'Successful'}]

const init = false
const reducer = (state, active) =>{
	switch(active.type){
		case 'basicModal' :
			return { ...state, basicModal: !state.basicModal}
		case 'smallModal'	:
			return { ...state, smallModal: !state.smallModal}		
            case 'modalWithTooltip'	:
                return { ...state, modalWithTooltip: !state.modalWithTooltip}		
            case 'gridInsideModal'	:
                return { ...state, gridInsideModal: !state.gridInsideModal}
            case 'largeModal'	:
                return { ...state, largeModal: !state.largeModal}
		default :	
			return state;
       
	}	
}

const ProgrammeIndex = () => {
  const dispatches = useDispatch();
  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_BASE_URL;  
  const [checked, setChecked] = useState(examTopper);
  const [unchecked, setUnChecked] = useState(true);
  const [state ,dispatch] = useReducer(reducer, init);
  const [error, setError] = useState(null);
  const [data, setData] =useState([]);
  const [sessions, setSession] =useState([]);
  const [ID, setID] =useState([]);
  const [code, setCode] =useState([]);
  const [fieldValue, setFieldValue]=useState();
  const [dataSession, setDataSession]=useState([]);
  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  const [dataFaculty, setDataFaculty] =useState([]);
  const [selectSessionValue, setselectSessionValue] = useState();
  const userID = userDetails.userID;
  const headers   = {'Content-Type': 'application/json', 'Authorization-Key': `${userDetails.apikey}`};
  const [selectFacultyValue, setselectFacultyValue] = useState();
  const [department, setDepartment] =useState([]);
  const [programmes, setProgrammes] =useState([]);
  const [selectDepartmentValue,setselectDepartmentValue]=useState();
  const handleChecked = (id)=> {
        let temp = checked.map((data) => {
            if (id === data.id) {
                return { ...data, inputchecked: !data.inputchecked };
            }
            return data;
        });
        setChecked(temp);
    };
    const handleCheckedAll = (value)=> {
        let temp = checked.map((data) => {          
            return { ...data, inputchecked: value };   
        });
        setChecked(temp);
        setUnChecked(!unchecked);
    };


    useEffect(()=>{
        fetchProgramme(userID)
   },[])

   const fetchProgramme = async () => {
    try {  
        const userData= {userID:userDetails.userID}
        
         axios.get(`${baseUrl}saveprogramme/${userID}`).then(response =>
         {
              //console.log(response);
              if(response.data.statuscode===200)
              {
                setData(response.data.data);
              }
              else 
              {
                throw new Error('Network response was not ok');
              }
         }).catch((error) => {
          if (error.response) {
              //console.log(error.response);
              //console.log("server responded");
          } else if (error.request) {
             // console.log("network error");
          } else {
              //console.log(error);
          }
          });
       
      } catch (error) {
        throw new Error(`Error fetching items: ${error.message}`);
      }
  };
  useEffect(()=>{
    const fetchFacultyData = async (userID) =>
    {
        try 
        {
          const response = await fetchItemsFaculty(userID);
          const customHeadings = response.data.map(item=>({
              "value": item.facultycode,
              "label": item.name        
           }))
           setDataFaculty(customHeadings);
        } catch (error) {
          setError(error.message);
        }
      };
      fetchFacultyData(userID)
    },[userID])
  function handleModalSubmit(e)
  {
      e.preventDefault();
      const path ="programmeupdate";
      const userData ={
                  id :ID,
                  departmentcode:selectDepartmentValue.value,
                  facultycode:selectFacultyValue.value,
                  name : e.target.programme.value,
                  programmecode : e.target.programmecode.value,
                  userID:userDetails.userID,
                  createdBy:userDetails.email,
          } 
       
        dispatches(saveAction(userData,path,navigate));
        fetchProgramme(userID)
        dispatch({type:'largeModal'})
  }
 function handleModalDelete(id)
 {
          axios.delete(`${baseUrl}deleteprogramme/${id}`,{headers}).then(response =>
          {       
            toast.success(response.data.error)
            fetchProgramme(userID)
            dispatch({type:'smallModal'})
          }).catch((error) => {
              if (error.response) {
                  //console.log(error.response.data.statuscode);
                  toast.error(error.response.data.error)
              } else if (error.request) {
                  //console.log("network error");
              } else {
                  //console.log(error);
              }
              });

 }

 const handleCodeInputChange = (e) => {
    setCode(e.target.value);
};
const handleProgrammeInputChange = (e) => {
    setProgrammes(e.target.value);
};



const loadFaculty =()=>{
    axios.get(`${baseUrl}savefaculty/${userDetails.userID}`,{headers}).then(response => 
      {
        
         const customHeadings = response.data.data.map(item=>({
                     "value": item.facultycode,
                     "label": item.name        
                  }))
                  setDataFaculty(customHeadings);
          }) .catch((error) => {
          if (error.response) {
              console.log(error.response);
              console.log("server responded");
          } else if (error.request) {
              console.log("network error");
          } else {
              console.log(error);
          }
          });
  }

  useEffect(()=>{
    loadFaculty()
  },[])

const selectFacultyOnChange = (selectedOption) => 
{
  setFieldValue('title', selectedOption.value);
  setselectFacultyValue(selectedOption);

  const facultyID = selectedOption.value;
   axios.get(`${baseUrl}savedepartment/departmentlist/${userDetails.userID}/${facultyID}`,{headers}).then(response => 
    {              
        //console.log(response);
               const customHeadings = response.data.data.map(item=>({
                  "value":item.departmentcode,
                  "label": item.name,
                 
                }))
                setDepartment(customHeadings);

        }) .catch((error) => {
        if (error.response) {
           
            console.log("server responded");
        } else if (error.request) {
            console.log("network error");
        } else {
            console.log(error);
        }
        });
};
const selectDepartmentOnChange = (selectedOption) => 
{
  setFieldValue('department', selectedOption.value);
  setselectDepartmentValue(selectedOption);
}


  return (
    <Fragment>
      <ToastContainer position="top-right"></ToastContainer>
      <div className="container-fluid">
        <Row>
         <Col lg={1}></Col>
          <Col lg={8}>
            <Card>
              <Card.Header>
                <Card.Title>List Programme</Card.Title>
              </Card.Header>
              <Card.Body>
              <Table responsive striped security='1'> 
                  <thead>
                    <tr>
                   
                      <th><strong>NO.</strong></th>
                      <th><strong>ID</strong></th>
                      <th><strong>Code</strong></th>
                      <th><strong>Name</strong></th>
                      <th><strong>DeptID</strong></th>
                      <th><strong>Deptcode</strong></th>
                      <th><strong>Dept.</strong></th>
                    
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item, index)=>(
                      <tr key={index}>
                        
                        
						<td>{++index}</td>
                        <td>{item.id}</td>
                        <td>{item.programmecode}</td>
                        <td>{item.programme}</td>
                        <td>{item.departmentid}</td>
                        <td>{item.departmentcode}</td>
                        <td>{item.department}</td>
                        <td>
                          <div className="d-flex">
                            <Link
                              to={"#"}
                                onClick={() => dispatch({type:'largeModal'}, setID(item.id), setCode(item.programmecode) ,  setProgrammes(item.programme), setDepartment(item.department))}
                                              className="shadow btn btn-primary btn-xs sharp me-1"
                            >
                              <i className="fas fa-pencil-alt"></i>
                            </Link>
                            <Link
                              onClick={() => dispatch({type:'smallModal'},setID(item.id), setProgrammes(item.programme), )}
                              to={"#"}
                              className="shadow btn btn-danger btn-xs sharp"
                            >
                              <i className="fa fa-trash"></i>
                            </Link>
                          </div>
                        </td>
                      </tr>
                    ))}
                    
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>     
        </Row>
      </div>

           {/* <!-- Modal --> */} 
           
          
              <Modal className="fade" show={state.largeModal} size="lg" onHide={()=>dispatch({type:'largeModal'})}> 
              <form  onSubmit={handleModalSubmit}>
                <Modal.Header>
                  <Modal.Title>Edit Programme</Modal.Title>
                  <Button variant="" className="btn-close" onClick={() => dispatch({type:'largeModal'})}></Button>
                </Modal.Header>
                <Modal.Body>
                  
                              <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-2 m-b30">
                                            <label className="form-label">Code </label>
                                            <input type="text" className="form-control" value={code} onChange={handleCodeInputChange} name="programmecode" id="programmecode" readOnly required/>
                                        </div>
                             
                                        <div className="col-sm-3 m-b30">
                                            <label className="form-label">Programme </label>
                                            <input type="text" className="form-control" value={programmes} onChange={handleProgrammeInputChange} name="programme" id="programme" placeholder="Computer Science" required />
                                        </div>
                                        <div className="col-sm-3 m-b30">
                                            <label className="form-label">Faculty</label>
                                            <Select placeholder='Faculty ' name="searchValueFaculty"  isSearchable={true} value={selectFacultyValue} onChange={selectFacultyOnChange} options={dataFaculty} required  />
                                        </div>
                                        <div className="col-sm-4 m-b30">
                                            <label className="form-label">Department Name</label>
                                            <Select placeholder='Department ' name="searchValueDepartment"  isSearchable={true} value={selectDepartmentValue} onChange={selectDepartmentOnChange} options={department} required  />
                                        </div>
                                    </div>
                                </div>
                                  
                            
                

                </Modal.Body>
                <Modal.Footer>
                  <Button  variant="danger light" onClick={() => dispatch({type:'largeModal'})}>Close</Button>
                  <button className="btn btn-primary" type="submit">Update</button>
                  {/* <Button variant="primary">Update </Button> */}
                </Modal.Footer> 
                </form>
              </Modal>
       
         



         {/* Delete Modal */}
         <Modal className="fade bd-example-modal-sm" size="sm" show={state.smallModal} onHide={()=>dispatch({type:'smallModal'})}>
         {/* <form  onSubmit={handleModalDelete}> */}
			      <Modal.Header><Modal.Title>Delete Programme</Modal.Title>
                 <Button variant="" className="btn-close"	onClick={() => dispatch({type:'smallModal'})}></Button>
		       	</Modal.Header>
           <Modal.Body>
             <span style={{ color:'red' }}>Are you sure you want to delete {programmes} information?</span>
          </Modal.Body>
			    <Modal.Footer>
            <Button  variant="danger light" onClick={() => dispatch({type:'smallModal'})}> Close </Button>
            <Button variant="danger" onClick={()=>handleModalDelete(ID)}>Delete</Button>
			    </Modal.Footer>
         {/* </form> */} 
        </Modal>
    </Fragment>
  );

};

export default ProgrammeIndex;
