import React,{useState, useEffect} from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import {  useDispatch } from 'react-redux';
import {loadingToggleAction, uploadActions } from '../../../../../../src/store/actions/AuthActions'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import  { fetchItemsSession } from '../../Lists/Session';
import Select from 'react-select';
const Index = () => {
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const createdBy = userDetails.email;
    const userID = userDetails.userID;
       const [dataSession, setDataSession] =useState([]);
       const [selectSessionValue, setselectSessionValue] = useState();
       const [fieldValue, setFieldValue]=useState();
       const [error, setError] = useState(null);
    const [file, setFile] = useState([]);
    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        setFile(e.target.files[0]);
       // console.log(file);
      }
    };
    function handleChange(e) 
    {
      if (!file) {
         return;
       }
       var formData = new FormData();
       formData.append("fileImage",file); 
       e.preventDefault();
     
       const path ="semesterupload";

       const userData = {
             sessionID : selectSessionValue.value,
             userID: userID,
             createdBy:createdBy,
             path: path,
             file:file
            };
     
           dispatch(uploadActions(userData, navigate));
    }


    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await fetchItemsSession();
            const customHeadings = response.data.map(item=>({
                "value": item.id,
                "label": item.name        
             }))
            setDataSession(customHeadings);
          } catch (error) {
            setError(error.message);
          }
        };
    
        fetchData();
      }, []); 
    
      const selectSessionOnChange = (selectedOption) => 
      {
        setFieldValue('session', selectedOption.value);
        setselectSessionValue(selectedOption);
      }
    return(
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-1 col-lg-4">
                 
                    </div>
                    <div className="col-xl-9 col-lg-8">
                        <div className="card profile-card card-bx m-b30">
                            <div className="card-header">
                                <h6 className="title">Upload Semester </h6>
                            </div>
                            <form className="profile-form" onSubmit={handleChange}>
                                <div className="card-body">
                                <div className="row">
                                    <div className="col-sm-4 m-b30">
                                             <label className="form-label">Session </label>
                                             <Select options={dataSession}  className="custom-react-select" 
                                                            placeholder="Session"
                                                            value={selectSessionValue} onChange={selectSessionOnChange}
                                                            isSearchable={true} />                                    
                                        
                                      </div>
                                  
                                        <div className="col-sm-8 m-b30">
                                            <label className="form-label">Upload Semester</label><a href='/downloadables/semester.csv' target="_blank"  ><span style={{ color:'red' }}><em>Download Sample Data Format</em></span></a>
                                            <input className="form-control" type="file" id="file" onChange={handleFileChange} />
                                        </div>
                                       
                                      
                                        
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <button className="btn btn-primary">Upload Now</button>
                                  
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Index;