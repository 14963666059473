import React,{useState,useEffect} from 'react';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import {useNavigate } from "react-router-dom";
import {useDispatch } from 'react-redux';
import axios from 'axios';
import {saveAction } from '../../../../../../src/store/actions/AuthActions'
import  { fetchItemsFaculty } from '../../Lists/Faculty';
import DepartmentIndex from '../../Reports/Views/Department/'
const Index = () => {
    const dispatches = useDispatch();
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const [userinfo, setUserInfo] =useState([]);
    const [dataFaculty, setDataFaculty] =useState([]);
    const [file, setFile] = useState();
    const [selectFacultyValue, setselectFacultyValue] = useState();
    const [fieldValue, setFieldValue]=useState();
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const userID =userDetails.userID;
    
useEffect(()=>{
const fetchFacultyData = async (userID) =>
{
    try 
    {
      const response = await fetchItemsFaculty(userID);
      const customHeadings = response.data.map(item=>({
          "value": item.facultycode,
          "label": item.name        
       }))
       setDataFaculty(customHeadings);
    } catch (error) {
      setError(error.message);
    }
  };
  fetchFacultyData(userID)
},[userID])



const selectFacultyOnChange = (selectedOption) => 
{
  setFieldValue('faculty', selectedOption.value);
  setselectFacultyValue(selectedOption);
};

function handleSubmit(e) 
{
  e.preventDefault();
  const path ="savedepartment";
  const userData ={
             
               facultycode:selectFacultyValue.value,
               name : e.target.department.value,
               departmentcode : e.target.departmentcode.value,
               userID:userDetails.userID,
               createdBy:userDetails.email,
     }
  dispatches(saveAction(userData,path,navigate));
  //window.location.reload();
}
    return(
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-1 col-lg-4">
                 
                    </div>
                    <div className="col-xl-8 col-lg-8">
                        <div className="card profile-card card-bx m-b30">
                            <div className="card-header">
                                <h6 className="title">Create Department </h6>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className="card-body">
                                    <div className="row">
                            
                                       
                                       <div className="col-sm-2 m-b30">
                                            <label className="form-label">Code</label>
                                            <input type="text" className="form-control" name="departmentcode" id="departmentcode" placeholder="Code"  required/>
                                        </div>
                                        <div className="col-sm-5 m-b30">
                                            <label className="form-label">Department Name</label>
                                            <input type="text" className="form-control" name="department" id="department" placeholder="Computer Science"  required/>
                                        </div>
                                       
                                        <div className="col-sm-5 m-b30">
                                            <label className="form-label">Faculty</label>
                                            <Select placeholder='Faculty'  isSearchable={true} name="searchValueFaculty" value={selectFacultyValue} onChange={selectFacultyOnChange} options={dataFaculty} required  />
                                        </div>
                                        
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <button className="btn btn-primary">Add Department</button>
                                  
                                </div>
                            </form>
                        </div>
                    </div>
                    <DepartmentIndex />
                </div>
            </div>
        </>
    )
}
export default Index;