import React,{useState, useEffect} from 'react';
import Select from 'react-select';
import {useNavigate } from "react-router-dom";
import {useDispatch } from 'react-redux';
import {saveAction } from '../../../../../../src/store/actions/AuthActions'
import DatePicker from "react-datepicker";
import { IMAGES } from '../../../../constant/theme';
import axios from 'axios';
import  { fetchItemDegrees } from '../../Lists/DegreeClass';
import  { fetchItemGraduationYear } from '../../Lists/GraduationYear';
const options4 = [
    { value: 'Male', label: 'Male' },
    { value: 'Female', label: 'Female' },
]

const entryYear = [];
const currentYear = new Date().getFullYear();

for (let year = 1960; year <= currentYear; year++) {
    entryYear.push({ value: year, label: year });
}

const graduationYear = [];
for (let year = 1960; year <= currentYear; year++) {
    graduationYear.push({ value: year, label: year });
}

const Index = () => {
    const [startDate2, setStartDate2] = useState(new Date());
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const dispatches = useDispatch();
    const navigate = useNavigate();
    const [userinfo, setUserInfo] =useState([]);
    const [dataDepartment, setDataDepartment] =useState([]);
    const [dataCountry, setDataCountry] =useState([]);
    const [dataDegree, setDataDegree] =useState([]);
    const [dataState, setDataState] =useState([]);
    const [dataGraudationyear, setDataGraudationYear] =useState([]);
    const [file, setFile] = useState();
    const [dataFaculty, setDataFaculty] =useState([]);
    const [error, setError] = useState(null);
    const [selectDepartmentValue, setselectDepartmentValue] = useState();
    const [selectFacultyValue, setselectFacultyValue] = useState();
    const [selectGraduationYearValue, setselectGraduationYearValue] = useState();
    const [selectEntryYearValue, setselectEntryYearValue] = useState();
    const [selectDegreeValue, setselectDegreeValue] = useState();
    const [selectGenderValue, setselectGenderValue] = useState();
    const [selectStateValue, setselectStateValue] = useState();
    const [selectCountryValue, setselectCountryValue] = useState();
    const [fieldValue, setFieldValue]=useState();

    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const userID = userDetails.userID;
    const loadFaculty =()=>{
        axios.get(`${baseUrl}savefaculty/${userDetails.userID}`).then(response => 
          {
             const customHeadings = response.data.data.map(item=>({
                         "value": item.facultycode,
                         "label": item.name        
                      }))
                      setDataFaculty(customHeadings);
              }) .catch((error) => {
              if (error.response) {
                  console.log(error.response);
                  console.log("server responded");
              } else if (error.request) {
                  console.log("network error");
              } else {
                  console.log(error);
              }
              });
      }
 

    

const loadCountry =()=>{
    axios.get(`${baseUrl}savecountry`).then(response => 
      {
        //console.log(response);
         const customHeadings = response.data.data.map(item=>({
                     "value": item.id,
                     "label": item.name        
                  }))
                  setDataCountry(customHeadings);
          }) .catch((error) => {
          if (error.response) {
              console.log(error.response);
              console.log("server responded");
          } else if (error.request) {
              console.log("network error");
          } else {
              console.log(error);
          }
          });
  }
useEffect(()=>{
    loadCountry()
    loadFaculty()
  },[])
  const loadState =(countryID)=>{
    axios.get(`${baseUrl}savestate/0/0/${countryID}`).then(response => 
      {
         
         const customHeadings = response.data.data.map(item=>({
                     "value": item.id,
                     "label": item.name        
                  }))
                  setDataState(customHeadings);
          }) .catch((error) => {
          if (error.response) {
              console.log(error.response);
              console.log("server responded");
          } else if (error.request) {
              console.log("network error");
          } else {
              console.log(error);
          }
          });
  }
  const selectCountryOnChange = (selectedOption) => 
  {
    setFieldValue('country', selectedOption.value);
    setselectCountryValue(selectedOption);
    
    const countryID = selectedOption.value;
    loadState(countryID)
  }
  
  const selectStateOnChange = (selectedOption) => 
  {
    setFieldValue('country', selectedOption.value);
    setselectStateValue(selectedOption);
  }

  const selectFacultyOnChange = (selectedOption) => 
  {
    setFieldValue('facultyID', selectedOption.value);
    setselectFacultyValue(selectedOption);
  
    const facultyID = selectedOption.value;
     axios.get(`${baseUrl}savedepartment/departmentlist/${userDetails.userID}/${facultyID}`).then(response => 
      {              
          //console.log(response);
                 const customHeadings = response.data.data.map(item=>({
                    "value":item.departmentcode,
                    "label": item.name,
                   
                  }))
                  setDataDepartment(customHeadings);
  
          }) .catch((error) => {
          if (error.response) {
             
              console.log("server responded");
          } else if (error.request) {
              console.log("network error");
          } else {
              console.log(error);
          }
          });
  };
  const selectDepartmentOnChange = (selectedOption) => 
  {
    setFieldValue('department', selectedOption.value);
    setselectDepartmentValue(selectedOption);
  }
  
const selectGraduationYearOnChange = (selectedOption) => 
{
  setFieldValue('graduationyear', selectedOption.value);
  setselectGraduationYearValue(selectedOption);
};

const selectGenderOnChange = (selectedOption) => 
{
  setFieldValue('title', selectedOption.value);
  setselectGenderValue(selectedOption);
};

const selectDegreeOnChange = (selectedOption) => 
{
  setFieldValue('degree', selectedOption.value);
  setselectDegreeValue(selectedOption);
};
const selectEntryYearOnChange = (selectedOption) => 
{
  setFieldValue('entryyear', selectedOption.value);
  setselectEntryYearValue(selectedOption);
};
function handleSubmit(e) 
{
    e.preventDefault();
    const path ="savestudentrecords";
    const userData ={
                matricno:e.target.matricno.value,
                surname : e.target.surname.value,
                firstname : e.target.firstname.value,
                othername:e.target.othername.value,
                email:e.target.email.value,
                gender:selectGenderValue.value,
                graduationyear:selectGraduationYearValue.value,
                dob: formatDob(e.target.dob.value),
                departmentcode:selectDepartmentValue.value,
                facultycode:selectFacultyValue.value,
                phone: e.target.phone.value,
                cgpa: e.target.cgpa.value,
                address:e.target.address.value,
                entryyear:selectEntryYearValue.value,
                degreeofclass:selectDegreeValue.value,
                stateID:selectStateValue.value,
                countryID:selectCountryValue.value,
                userID:userDetails.userID,
                createdBy:userDetails.email,
        }
       
    dispatches(saveAction(userData,path,navigate));
}

function formatDob(dateString) {
    const [month, day, year] = dateString.split('/');
    return `${day}-${month}-${year}`;
}

useEffect(()=>{
    const fetchDegreeData = async (userID) => {
        try 
        {
           const response = await fetchItemDegrees(userID);
           const customHeadings = response.data.map(item=>({
            "value": item.id,
            "label":item.name      
         }))
         setDataDegree(customHeadings);
        } catch (error) {
          setError(error.message);
        }
      };
      fetchDegreeData(userID)
 },[userID])


 useEffect(()=>{
    const fetchGraduationData = async (userID) => {
        try 
        {
           const response = await fetchItemGraduationYear(userID);
           const customHeadings = response.data.map(item=>({
            "value": item.year,
            "label":item.year      
         }))
         setDataGraudationYear(customHeadings);
        } catch (error) {
          setError(error.message);
        }
      };
      fetchGraduationData(userID)
 },[userID])

    return(
        <>
            <div className="container-fluid">
                <div className="row">
                   
                    <div className="col-xl-12 col-lg-8">
                        <div className="card profile-card card-bx m-b30">
                            <div className="card-header">
                                <h6 className="title">Create Student </h6>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className="card-body">
                                    <div className="row">
                                   
                                        <div className="col-sm-3 m-b30">
                                            <label className="form-label">Matric No. </label>
                                            <input type="text" className="form-control" id="matricno" name="matricno" placeholder="Student Matriculation No" required/>
                                        </div>
                                        <div className="col-sm-3 m-b30">
                                            <label className="form-label">Surname </label>
                                            <input type="text" className="form-control" id="surname" name="surname" placeholder="Surname" required/>
                                        </div>

                                        <div className="col-sm-3 m-b30">
                                            <label className="form-label">First Name </label>
                                            <input type="text" className="form-control" id="firstname"  name="firstname" placeholder="First Name" required />
                                        </div>

                                        <div className="col-sm-3 m-b30">
                                            <label className="form-label">Other name </label>
                                            <input type="text" className="form-control" name="othername" id="othername" placeholder="Othername" />
                                        </div>
                                       
                                        <div className="col-sm-5 m-b30">
                                            <label className="form-label">Email </label>
                                            <input type="email" className="form-control" id="email" name="email" placeholder="Email Address" required />
                                        </div>
                                        <div className="col-sm-3 m-b30">
                                            <label className="form-label">Phone </label>
                                            <input type="text" className="form-control" id="phone" name="phone" placeholder="Phone Number" required />
                                        </div>
                                        <div className="col-sm-2 m-b30">
                                            <label className="form-label">Gender</label>
                                            <Select options={options4} onChange={selectGenderOnChange} value={selectGenderValue} className="custom-react-select" 
                                            placeholder="Gender" id="gender" name="Gender"  
                                            isSearchable={true} required
                                        /></div>
                                        <div className="col-sm-2 m-b30">
                                            <label className="form-label">Birth</label>                                              
                                            <DatePicker name="dob" id="dob"
                                                className="form-control"
                                                selected={startDate2} 
                                                onChange={(date) => setStartDate2(date)} 
                                                required
                                            />
                                        </div>
                                        <div className="col-sm-7 m-b30">
                                            <label className="form-label">Address </label>
                                            <input type="text" className="form-control" id="address" name="address" placeholder="Address" required />
                                        </div>
                                        <div className="col-sm-3 m-b30">
                                            <label className="form-label">Country</label>
                                            <Select options={dataCountry} value={selectCountryValue}  className="custom-react-select" onChange={selectCountryOnChange} 
                                               placeholder="Country"
                                                isSearchable={true}
                                            />
                                              {/* <Select placeholder='Country ' name="searchValueCountry"  isSearchable={true} value={selectCountryValue} onChange={selectCountryOnChange} options={dataCountry} required  /> */}
                                        </div>
                                        <div className="col-sm-2 m-b30">
                                            <label className="form-label">State</label>
                                            <Select options={dataState}  className="custom-react-select"  onChange={selectStateOnChange} value={selectStateValue}
                                                placeholder="States"
                                                isSearchable={true} required
                                            />
                                        </div>
                                       
                                        
                                         <div className="col-sm-3 m-b30">
                                            <label className="form-label">Faculty</label>
                                            <Select placeholder='Faculty ' name="searchValueFaculty"  isSearchable={true} value={selectFacultyValue} onChange={selectFacultyOnChange} options={dataFaculty} required  />
                                        </div>
                                        <div className="col-sm-3 m-b30">
                                            <label className="form-label">Department</label>
                                            <Select options={dataDepartment} name="departmentID" id='departmentID' onChange={selectDepartmentOnChange} value={selectDepartmentValue}  className="custom-react-select" 
                                             placeholder="Department" 
                                            isSearchable={true} required
                                        />
                                         </div>
                                         <div className="col-sm-2 m-b30">
                                                <label className="form-label">Year of Entry</label>
                                                <Select options={entryYear} value={selectEntryYearValue} onChange={selectEntryYearOnChange}  className="custom-react-select" 
                                                defaultValue={entryYear[0]}
                                                isSearchable={true} required
                                            />
                                            </div>
                                           <div className="col-sm-2 m-b30">
                                                <label className="form-label">Year of Graduation</label>
                                                <Select options={dataGraudationyear} value={selectGraduationYearValue} onChange={selectGraduationYearOnChange}  className="custom-react-select" 
                                                defaultValue={dataGraudationyear[0]}
                                                isSearchable={true} required
                                            />
                                            </div>
                                            <div className="col-sm-2 m-b30">
                                                <label className="form-label">Class of Degree</label>
                                                <Select options={dataDegree} value={selectDegreeValue} onChange={selectDegreeOnChange}  className="custom-react-select" 
                                                defaultValue={dataDegree[0]}
                                                isSearchable={true} required
                                            />
                                            </div>
                                            
                                            <div className="col-sm-2 m-b30">
                                                <label className="form-label">CGPA </label>
                                            <input type="text" className="form-control" id="cgpa"  name="cgpa" placeholder="CGPA" required />
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <button className="btn btn-primary">Add Student</button>
                                  
                                </div>
                               
                                
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Index;