import React, { Fragment, useState, useReducer, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Table,
  Modal,
  Button,

} from "react-bootstrap";

import { Link } from "react-router-dom";
import axios from 'axios';
import  { fetchItemsSession } from '../../../Lists/Session';
import {useNavigate } from "react-router-dom";
import {useDispatch } from 'react-redux';
import {saveAction } from '../../../../../../store/actions/AuthActions'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import  { fetchCountryZoneItems } from '../../../Lists/CountryZone';
import  { fetchZoneItems }        from '../../../Lists/Zones';
const examTopper = [{id:'111', color:'success', status:'Successful'}]

const init = false
const reducer = (state, active) =>{
	switch(active.type){
		case 'basicModal' :
			return { ...state, basicModal: !state.basicModal}
		case 'smallModal'	:
			return { ...state, smallModal: !state.smallModal}		
		default :	
			return state;
	}	
}

const ZoneIndex = () => {
  const dispatches = useDispatch();
  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_BASE_URL;  
  const [checked, setChecked] = useState(examTopper);
  const [unchecked, setUnChecked] = useState(true);
  const [state ,dispatch] = useReducer(reducer, init);
  const [error, setError] = useState(null);
  const [name, setName] =useState([]);
  const [data, setData] =useState([]);
  const [country, setCountry] =useState([])
  const [countryData, setCountryData] =useState([]);;
  const [zoneData, setZoneData] =useState([]);
  const [ID, setID] =useState([]);
  const [zone, setZone] =useState([]);
  const [fieldValue, setFieldValue]=useState();
  const [dataCountryZones, setCountryZones]=useState([]);
  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  const [selectCountryValue, setselectCountryValue] = useState();
  const [selectZoneValue, setselectZoneValue] = useState();

  
  const userID = userDetails.userID;
  const headers   = {'Content-Type': 'application/json', 'Authorization-Key': `${userDetails.apikey}`};

  const handleChecked = (id)=> {
        let temp = checked.map((data) => {
            if (id === data.id) {
                return { ...data, inputchecked: !data.inputchecked };
            }
            return data;
        });
        setChecked(temp);
    };
    const handleCheckedAll = (value)=> {
        let temp = checked.map((data) => {          
            return { ...data, inputchecked: value };   
        });
        setChecked(temp);
        setUnChecked(!unchecked);
    };


    useEffect(()=>{
      fetchCountryZones(userID)
   },[])

   const fetchCountryZones = async () => {
    try {  
        const userData= {userID:userDetails.userID}
        
         axios.get(`${baseUrl}savescountryzone/${userID}`).then(response =>
         {
             // console.log(response);
              if(response.data.statuscode===200)
              {
                setData(response.data.data);
              }
              else 
              {
                throw new Error('Network response was not ok');
              }
         }).catch((errors) => {
          if (errors.response) {
              console.log(errors.response);
              console.log("server responded");
          } else if (errors.request) {
             // console.log("network error");
          } else {
              //console.log(error);
          }
          });
       
      } catch (error) {
        throw new Error(`Error fetching items: ${error.message}`);
      }
  };
  
  const selectCountryChange = (selectedOption) => 
  {
    setFieldValue('country', selectedOption.value);
    setselectCountryValue(selectedOption);
  }

  const selectZoneChange = (selectedOption) => 
    {
      setFieldValue('zone', selectedOption.value);
      setselectZoneValue(selectedOption);
    }
  

  function handleModalSubmit(e)
  {
      e.preventDefault();
      const path ="countryzoneupdate";
      const userData ={
                  id :ID,
                  name: selectCountryValue.value,
                  zone :selectZoneValue.value,
                  userID:userDetails.userID,
                  createdBy:userDetails.email,
          } 
          
      dispatches(saveAction(userData,path,navigate));
      fetchCountryZones(userID)
      dispatch({type:'basicModal'})
  }
  const handleInputChange = (e) => {
      setName(e.target.value);
  };
  const handleInputAmountChange = (e) => {
    setZone(e.target.value);
};
 function handleModalDelete(id)
 {
          axios.delete(`${baseUrl}deletecountryzone/${id}`,{headers}).then(response =>
          {       
            toast.success(response.data.error)
            fetchCountryZones(userID)
            dispatch({type:'smallModal'})
          }).catch((error) => {
              if (error.response) {
                  //console.log(error.response.data.statuscode);
                  toast.error(error.response.data.error)
              } else if (error.request) {
                  //console.log("network error");
              } else {
                  //console.log(error);
              }
              });

 }


 useEffect(() => {
  const fetchCountry = async () => {
    try {
      const response = await fetchCountryZoneItems();
      //console.log(response);
      const customHeadings = response.data.map(item=>({
          "value": item.country,
          "label": item.country        
       }))
       setCountryData(customHeadings);
    } catch (error) {
      setError(error.message);
    }
  };
  fetchCountry();
}, []); 


useEffect(() => {
  const fetchZone = async () => {
    try {
      const response = await fetchZoneItems();
      //console.log(response);
      const customHeadings = response.data.map(item=>({
          "value": item.id,
          "label": item.name        
       }))
       setZoneData(customHeadings);
    } catch (error) {
      setError(error.message);
    }
  };
  fetchZone();
}, []); 

 

 return (
    <Fragment>
      <ToastContainer position="top-right"></ToastContainer>
      <div className="container-fluid">
        <Row>
         <Col lg={1}></Col>
          <Col lg={6}>
            <Card>
              <Card.Header>
                <Card.Title>List Country and Zones</Card.Title>
              </Card.Header>
              <Card.Body>
              <Table responsive striped security='1'> 
                  <thead>
                    <tr>
                   
                      <th><strong>NO.</strong></th>
                      <th><strong>Zone</strong></th>
                      <th><strong>Country</strong></th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item, index)=>(
                      <tr key={index}>
                        
                        
						<td>{++index}</td>
                        <td>{item.zone}</td>
                        <td>{item.country}</td>
                    
                        <td>
                          <div className="d-flex">
                            <Link
                              to={"#"}
                                onClick={() => dispatch({type:'basicModal'}, setID(item.id), setCountry(item.country),setZone(item.zone))}
                                              className="shadow btn btn-primary btn-xs sharp me-1"
                            >
                              <i className="fas fa-pencil-alt"></i>
                            </Link>
                            <Link
                              onClick={() => dispatch({type:'smallModal'},setID(item.id), setCountry(item.name), setZone(item.zone))}
                              to={"#"}
                              className="shadow btn btn-danger btn-xs sharp"
                            >
                              <i className="fa fa-trash"></i>
                            </Link>
                          </div>
                        </td>
                      </tr>
                    ))}
                    
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>     
        </Row>
      </div>

           {/* <!-- Modal --> */} 
           
          
              <Modal className="fade" show={state.basicModal} onHide={()=>dispatch({type:'basicModal'})}> 
              <form  onSubmit={handleModalSubmit}>
                <Modal.Header>
                  <Modal.Title>Edit Country Zone</Modal.Title>
                  <Button variant="" className="btn-close" onClick={() => dispatch({type:'basicModal'})}></Button>
                </Modal.Header>
                <Modal.Body>
                  
                
                                  <div className="card-body">
                                      <div className="row">
                              
                                    
                                          <div className="col-sm-6 m-b30">
                                              <label className="form-label">Country [{ country }]</label>
                                              <Select options={countryData} value={selectCountryValue}  className="custom-react-select" onChange={selectCountryChange} 
                                                placeholder="Country"
                                                isSearchable={true} required
                                            />
                                     
                                           </div>
                                        
                                            <div className="col-sm-6 m-b30">
                                                  <label className="form-label">Zone [{zone}] </label>
                                                  <Select options={zoneData} value={selectZoneValue}  className="custom-react-select" onChange={selectZoneChange} 
                                                placeholder="Zone"
                                                isSearchable={true} required
                                            />    
                                            </div>                                      
                                      </div>
                                  </div>
                                  
                            
                

                </Modal.Body>
                <Modal.Footer>
                  <Button  variant="danger light" onClick={() => dispatch({type:'basicModal'})}>Close</Button>
                  <button className="btn btn-primary" type="submit">Update</button>
                  {/* <Button variant="primary">Update </Button> */}
                </Modal.Footer> 
                </form>
              </Modal>
       
         



         {/* Delete Modal */}
         <Modal className="fade bd-example-modal-sm" size="sm" show={state.smallModal} onHide={()=>dispatch({type:'smallModal'})}>
         {/* <form  onSubmit={handleModalDelete}> */}
			      <Modal.Header><Modal.Title>Delete  Country Zone</Modal.Title>
                 <Button variant="" className="btn-close"	onClick={() => dispatch({type:'smallModal'})}></Button>
		       	</Modal.Header>
           <Modal.Body>
             <span style={{ color:'red' }}>Are you sure you want to delete {zone} information?</span>
          </Modal.Body>
			    <Modal.Footer>
            <Button  variant="danger light" onClick={() => dispatch({type:'smallModal'})}> Close </Button>
            <Button variant="danger" onClick={()=>handleModalDelete(ID)}>Delete</Button>
			    </Modal.Footer>
         {/* </form> */} 
        </Modal>
    </Fragment>
  );

};

export default ZoneIndex;
