import React, { Fragment, useState, useReducer, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Table,
  Modal,
  Button,

} from "react-bootstrap";

import { Link } from "react-router-dom";
import axios from 'axios';
import  { fetchItemsSession } from '../../../Lists/Session';
import {useNavigate } from "react-router-dom";
import {useDispatch } from 'react-redux';
import {saveAction } from '../../../../../../store/actions/AuthActions'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';


const examTopper = [{id:'111', color:'success', status:'Successful'}]

const init = false
const reducer = (state, active) =>{
	switch(active.type){
		case 'basicModal' :
			return { ...state, basicModal: !state.basicModal}
		case 'smallModal'	:
			return { ...state, smallModal: !state.smallModal}		
		default :	
			return state;
	}	
}

const CourseIndex = () => {
  const dispatches = useDispatch();
  const navigate = useNavigate();

  const baseUrl = process.env.REACT_APP_BASE_URL;  
  const [checked, setChecked] = useState(examTopper);
  const [unchecked, setUnChecked] = useState(true);
  const [state ,dispatch] = useReducer(reducer, init);
  const [error, setError] = useState(null);
  const [data, setData] =useState([]);
  const [courier, setCourier] =useState();
  const [cost, setCost] =useState();
  const [location, setLocation] =useState();
  const [ID, setID] =useState([]);
  const [dataSession, setDataSession]=useState([]);
  const [selectSessionValue, setselectSessionValue] = useState();
  
  const [dataCourier, setDataCounrier] =useState([]);
  const [selectCourierValue, setselectCourierValue] = useState();
    const [fieldValue, setFieldValue]=useState();
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const [dataCountry, setDataCountry] =useState([]);
    const [dataState, setDataState] =useState([]);
    const [selectStateValue, setselectStateValue] = useState();
    const [selectCountryValue, setselectCountryValue] = useState();
    const [coursecode,  setCoursecode] =useState();
    const [coursetitle, setCoursetitle] =useState();
    const [description, setDescription] =useState();
  
  const headers   = {'Content-Type': 'application/json', 'Authorization-Key': `${userDetails.apikey}`};
  const userID = userDetails.userID;
    useEffect(()=>{
        fetchCourses(userID)
   },[])

   const fetchCourses = async () => {
    try {  
             
         axios.get(`${baseUrl}savescourses/${userID}`).then(response =>
         {
              //console.log(response);
              if(response.data.statuscode===200)
              {
                setData(response.data.data);
              }
              else 
              {
                throw new Error('Network response was not ok');
              }
         }).catch((error) => {
          if (error.response) {
              //console.log(error.response);
              //console.log("server responded");
          } else if (error.request) {
             // console.log("network error");
          } else {
              //console.log(error);
          }
          });
       
      } catch (error) {
        throw new Error(`Error fetching items: ${error.message}`);
      }
  };
 


  function handleModalSubmit(e)
  {
      e.preventDefault();
      const path ="courseupdate";
      const userData ={
                   id :ID,
                   coursecode  :e.target.coursecode.value,
                   coursetitle :e.target.coursetitle.value,
                   description :e.target.description.value,
                   userID:userDetails.userID,
                   createdBy:userDetails.email,
          } 
          
      dispatches(saveAction(userData,path,navigate));
      fetchCourses()
      dispatch({type:'basicModal'})
  }
  const handleCoursecodeChange = (e) => {
      setCoursecode(e.target.value);
  };
  const handleTitleChange = (e) => {
    setCoursetitle(e.target.value);
  };
  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };
 function handleModalDelete(id)
 {
          axios.delete(`${baseUrl}deletecourse/${id}`,{headers}).then(response =>
          {       
            toast.success(response.data.error)
            fetchCourses()
            dispatch({type:'smallModal'})
          }).catch((error) => {
              if (error.response) {
                  //console.log(error.response.data.statuscode);
                  toast.error(error.response.data.error)
              } else if (error.request) {
                  //console.log("network error");
              } else {
                  //console.log(error);
              }
              });

 }


  return (
    <Fragment>
      <ToastContainer position="top-right"></ToastContainer>
      <div className="container-fluid">
        <Row>
         <Col lg={1}></Col>
          <Col lg={8}>
            <Card>
              <Card.Header>
                <Card.Title>List of Courses</Card.Title>
              </Card.Header>
              <Card.Body>
              <Table responsive striped security='1'> 
                  <thead>
                    <tr>
                   
                      <th><strong>NO.</strong></th>
                      <th><strong>Coursecode</strong></th>
                      <th><strong>Course Title</strong></th>
                      <th><strong>Description</strong></th>
                  
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item, index)=>(
                      <tr key={index}>
						<td>{++index}</td>
                        <td>{item.coursecode}</td>
                        <td>{item.coursetitle}</td>
                        <td>{item.description}</td>
                        
                        <td>
                          <div className="d-flex">
                            <Link
                              to={"#"}
                                onClick={() => dispatch({type:'basicModal'}, setID(item.id), setCoursecode(item.coursecode), setCoursetitle(item.coursetitle), setDescription(item.description))}
                                              className="shadow btn btn-primary btn-xs sharp me-1"
                            >
                              <i className="fas fa-pencil-alt"></i>
                            </Link>
                            <Link
                              onClick={() => dispatch({type:'smallModal'},setID(item.id), setCoursecode(item.coursecode))}
                              to={"#"}
                              className="shadow btn btn-danger btn-xs sharp"
                            >
                              <i className="fa fa-trash"></i>
                            </Link>
                          </div>
                        </td>
                      </tr>
                    ))}
                    
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>     
        </Row>
      </div>

           {/* <!-- Modal --> */} 
           
          
              <Modal className="fade" show={state.basicModal} onHide={()=>dispatch({type:'basicModal'})}> 
              <form  onSubmit={handleModalSubmit}>
                <Modal.Header>
                  <Modal.Title>Edit Courses</Modal.Title>
                  <Button variant="" className="btn-close" onClick={() => dispatch({type:'basicModal'})}></Button>
                </Modal.Header>
                <Modal.Body>
                  
                
                          <div className="card-body">
                            <div className="row">
                                       <div className="col-sm-6 m-b30">
                                            {/* <label className="form-label">Coursecode</label> */}
                                            <input type="hidden" className="form-control" id="cost" value={coursecode} onChange={handleCoursecodeChange} name="coursecode" placeholder="coursecode" required />
                                        </div>
                              
                                        <div className="col-sm-12 m-b30">
                                            <label className="form-label">Coursetitle</label>
                                            <input type="text" className="form-control" id="cost" value={coursetitle} onChange={handleTitleChange} name="coursetitle" placeholder="coursetitle" required />
                                        </div>
                              
                                       
                                        <div className="col-sm-12 m-b30">
                                            <label className="form-label">Description </label>
                                            <input type="text" className="form-control" id="cost" value={description} onChange={handleDescriptionChange} name="description" placeholder="Description" required />
                                        </div>
                                         
                                        
                                    </div>
                                </div>
                                  
                </Modal.Body>
                <Modal.Footer>
                  <Button  variant="danger light" onClick={() => dispatch({type:'basicModal'})}>Close</Button>
                  <button className="btn btn-primary" type="submit">Update</button>
                  {/* <Button variant="primary">Update </Button> */}
                </Modal.Footer> 
                </form>
              </Modal>
       
         



         {/* Delete Modal */}
         <Modal className="fade bd-example-modal-sm" size="sm" show={state.smallModal} onHide={()=>dispatch({type:'smallModal'})}>
         {/* <form  onSubmit={handleModalDelete}> */}
			      <Modal.Header><Modal.Title>Delete Course</Modal.Title>
                 <Button variant="" className="btn-close"	onClick={() => dispatch({type:'smallModal'})}></Button>
		       	</Modal.Header>
           <Modal.Body>
             <span style={{ color:'red' }}>Are you sure you want to delete {coursecode} level of {coursetitle} session information?</span>
          </Modal.Body>
			    <Modal.Footer>
            <Button  variant="danger light" onClick={() => dispatch({type:'smallModal'})}> Close </Button>
            <Button variant="danger" onClick={()=>handleModalDelete(ID)}>Delete</Button>
			    </Modal.Footer>
         {/* </form> */} 
        </Modal>
    </Fragment>
  );

};

export default CourseIndex;
