import React, { Fragment, useState, useReducer, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Table,
  Modal,
  Button,

} from "react-bootstrap";

import { Link } from "react-router-dom";
import axios from 'axios';
import {useNavigate } from "react-router-dom";
import {useDispatch } from 'react-redux';
import {saveAction } from '../../../../../../store/actions/AuthActions'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';


const examTopper = [{id:'111', color:'success', status:'Successful'}]

const init = false
const reducer = (state, active) =>{
	switch(active.type){
		case 'basicModal' :
			return { ...state, basicModal: !state.basicModal}
		case 'smallModal'	:
			return { ...state, smallModal: !state.smallModal}		
            case 'modalWithTooltip'	:
                return { ...state, modalWithTooltip: !state.modalWithTooltip}		
            case 'gridInsideModal'	:
                return { ...state, gridInsideModal: !state.gridInsideModal}
            case 'largeModal'	:
                return { ...state, largeModal: !state.largeModal}
		default :	
			return state;
       
	}	
}

const DegreeIndex = () => {
  const dispatches = useDispatch();
  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_BASE_URL;  
  const [checked, setChecked] = useState(examTopper);
  const [unchecked, setUnChecked] = useState(true);
  const [state ,dispatch] = useReducer(reducer, init);
  const [error, setError] = useState(null);
  const [data, setData] =useState([]);
  const [degree, setDegree] =useState([]);
  const [ID, setID] =useState([]);
  const [fieldValue, setFieldValue]=useState();
  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  const userID = userDetails.userID;
  const headers   = {'Content-Type': 'application/json', 'Authorization-Key': `${userDetails.apikey}`};
  const handleChecked = (id)=> {
        let temp = checked.map((data) => {
            if (id === data.id) {
                return { ...data, inputchecked: !data.inputchecked };
            }
            return data;
        });
        setChecked(temp);
    };
    const handleCheckedAll = (value)=> {
        let temp = checked.map((data) => {          
            return { ...data, inputchecked: value };   
        });
        setChecked(temp);
        setUnChecked(!unchecked);
    };


    useEffect(()=>{
        fetchDegree(userID)
   },[])

   const fetchDegree = async () => {
    try {  
        
        
         axios.get(`${baseUrl}savedegreeclass/${userID}`).then(response =>
         {
              //console.log(response);
              if(response.data.statuscode===200)
              {
                setData(response.data.data);
              }
              else 
              {
                throw new Error('Network response was not ok');
              }
         }).catch((error) => {
          if (error.response) {
              //console.log(error.response);
              //console.log("server responded");
          } else if (error.request) {
             // console.log("network error");
          } else {
              //console.log(error);
          }
          });
       
      } catch (error) {
        throw new Error(`Error fetching items: ${error.message}`);
      }
  };

  function handleModalSubmit(e)
  {
      e.preventDefault();
      const path ="degreeclassupdate";
      const userData ={
                  id :ID,
                  name:e.target.name.value,
                  userID:userDetails.userID,
                  createdBy:userDetails.email,
          } 
        dispatches(saveAction(userData,path,navigate));
        fetchDegree()
        dispatch({type:'largeModal'})
  }
 function handleModalDelete(id)
 {
          axios.delete(`${baseUrl}deletedegreeclass/${id}`,{headers}).then(response =>
          {       
            toast.success(response.data.error)
            fetchDegree(userID)
            dispatch({type:'smallModal'})
          }).catch((error) => {
              if (error.response) {
                  //console.log(error.response.data.statuscode);
                  toast.error(error.response.data.error)
              } else if (error.request) {
                  //console.log("network error");
              } else {
                  //console.log(error);
              }
              });

 }

const handleNameInputChange = (e) => {
    setDegree(e.target.value);
};

  return (
    <Fragment>
      <ToastContainer position="top-right"></ToastContainer>
      <div className="container-fluid">
        <Row>
         <Col lg={1}></Col>
          <Col lg={5}>
            <Card>
              <Card.Header>
                <Card.Title>List Class of Degree</Card.Title>
              </Card.Header>
              <Card.Body>
              <Table responsive striped security='1'> 
                  <thead>
                    <tr>
                   
                      <th><strong>NO.</strong></th>
                      <th><strong>ID</strong></th>
                      <th><strong>Name</strong></th>
             
                    
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item, index)=>(
                      <tr key={index}>
                        
                        
						<td>{++index}</td>
                        <td>{item.id}</td>
                        <td>{item.name}</td>
                        <td>
                          <div className="d-flex">
                            <Link
                              to={"#"}
                                onClick={() => dispatch({type:'largeModal'}, setID(item.id),setDegree(item.name))}
                                              className="shadow btn btn-primary btn-xs sharp me-1"
                            >
                              <i className="fas fa-pencil-alt"></i>
                            </Link>
                            <Link
                              onClick={() => dispatch({type:'smallModal'},setID(item.id), setDegree(item.name))}
                              to={"#"}
                              className="shadow btn btn-danger btn-xs sharp"
                            >
                              <i className="fa fa-trash"></i>
                            </Link>
                          </div>
                        </td>
                      </tr>
                    ))}
                    
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>     
        </Row>
      </div>

           {/* <!-- Modal --> */} 
           
          
              <Modal className="fade" show={state.largeModal}  onHide={()=>dispatch({type:'largeModal'})}> 
              <form  onSubmit={handleModalSubmit}>
                <Modal.Header>
                  <Modal.Title>Edit Class of Degree</Modal.Title>
                  <Button variant="" className="btn-close" onClick={() => dispatch({type:'largeModal'})}></Button>
                </Modal.Header>
                <Modal.Body>
                  
                
                                  <div className="card-body">
                                      <div className="row">
                              
                                    
                                      
                                            <div className="col-sm-5 m-b30">
                                                <label className="form-label">Class of Degree</label>
                                                <input type="text" className="form-control" value={degree} name="name" id="name" onChange={handleNameInputChange}   required/>
                                            </div>
                                        
                                          
                                          
                                      </div>
                                  </div>
                                  
                            
                

                </Modal.Body>
                <Modal.Footer>
                  <Button  variant="danger light" onClick={() => dispatch({type:'largeModal'})}>Close</Button>
                  <button className="btn btn-primary" type="submit">Update</button>
                  {/* <Button variant="primary">Update </Button> */}
                </Modal.Footer> 
                </form>
              </Modal>
       
         



         {/* Delete Modal */}
         <Modal className="fade bd-example-modal-sm" size="sm" show={state.smallModal} onHide={()=>dispatch({type:'smallModal'})}>
         {/* <form  onSubmit={handleModalDelete}> */}
			      <Modal.Header><Modal.Title>Delete Class of  Degree</Modal.Title>
                 <Button variant="" className="btn-close"	onClick={() => dispatch({type:'smallModal'})}></Button>
		       	</Modal.Header>
           <Modal.Body>
             <span style={{ color:'red' }}>Are you sure you want to delete {degree} information?</span>
          </Modal.Body>
			    <Modal.Footer>
            <Button  variant="danger light" onClick={() => dispatch({type:'smallModal'})}> Close </Button>
            <Button variant="danger" onClick={()=>handleModalDelete(ID)}>Delete</Button>
			    </Modal.Footer>
         {/* </form> */} 
        </Modal>
    </Fragment>
  );

};

export default DegreeIndex;
