import React,{useState, useEffect} from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import {  useDispatch } from 'react-redux';
import {loadingToggleAction, uploadActions } from '../../../../../../src/store/actions/AuthActions'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import  { fetchItemsFaculty } from '../../Lists/Faculty';
import Select from 'react-select';

const Index = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const createdBy = userDetails.email;
    const userID = userDetails.userID;
    const [dataFaculty, setDataFaculty] =useState([]);
    const [selectFacultyValue, setselectFacultyValue] = useState();
    const [dataDepartment, setDataDepartment] =useState([]);
    const [selectDepartmentValue, setselectDepartmentValue] = useState();
    const [fieldValue, setFieldValue]=useState();
    const [error, setError] = useState(null);
    const [file, setFile] = useState([]);
    var headers   = {'Content-Type': 'multipart/form-data', 'Authorization-Key': `${userDetails.apikey}`};
    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        setFile(e.target.files[0]);
        //console.log(file);
      }
    };
    function handleChange(e) 
    {
      if (!file) {
         return;
       }
       var formData = new FormData();
       formData.append("fileImage",file); 
       e.preventDefault();
       const path ="programmeupload";

        const userData =
        {
            facultycode:selectFacultyValue.value,
            departmentcode:selectDepartmentValue.value,
            userID: userID,
            createdBy:createdBy,
            path: path,
            file:file
        };
     
      
       dispatch(uploadActions(userData, navigate));
    }

    const selectFacultyOnChange = (selectedOption) => 
        {
          setFieldValue('title', selectedOption.value);
          setselectFacultyValue(selectedOption);
        
          const facultyID = selectedOption.value;
           axios.get(`${baseUrl}savedepartment/departmentlist/${userDetails.userID}/${facultyID}`,{headers}).then(response => 
            {              
                //console.log(response);
                       const customHeadings = response.data.data.map(item=>({
                          "value":item.departmentcode,
                          "label": item.name,
                         
                        }))
                        setDataDepartment(customHeadings);
        
                }) .catch((error) => {
                if (error.response) {
                   
                    console.log("server responded");
                } else if (error.request) {
                    console.log("network error");
                } else {
                    console.log(error);
                }
                });
        };
        
    
        
       const selectDepartmentOnChange = (selectedOption) => 
        {
          setFieldValue('faculty', selectedOption.value);
          setselectDepartmentValue(selectedOption);
        }
    
        useEffect(()=>{
        const fetchFacultyData = async (userID) =>
        {
            try 
            {
              const response = await fetchItemsFaculty(userID);
              const customHeadings = response.data.map(item=>({
                  "value": item.facultycode,
                  "label": item.name        
               }))
               setDataFaculty(customHeadings);
            } catch (error) {
              setError(error.message);
            }
          };
          fetchFacultyData(userID)
        },[userID])
        
    return(
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-1 col-lg-4">
                 
                    </div>
                    <div className="col-xl-9 col-lg-8">
                        <div className="card profile-card card-bx m-b30">
                            <div className="card-header">
                                <h6 className="title">Upload Programme </h6>
                            </div>
                            <form className="profile-form" onSubmit={handleChange}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-6 m-b30">
                                                <label className="form-label">Faculty</label>
                                                <Select options={dataFaculty}  className="custom-react-select" 
                                                                placeholder="Faculty"
                                                                value={selectFacultyValue} onChange={selectFacultyOnChange}
                                                                isSearchable={true} />      
                                        </div>
                                        <div className="col-sm-6 m-b30">
                                                <label className="form-label">Department</label>
                                                <Select options={dataDepartment}  className="custom-react-select" 
                                                                placeholder="Department"
                                                                value={selectDepartmentValue} onChange={selectDepartmentOnChange}
                                                                isSearchable={true} />      
                                        </div>
                                  
                                        <div className="col-sm-8 m-b30">
                                            <label className="form-label">Upload Programme</label><a href='/downloadables/programme.csv' target="_blank"  ><span style={{ color:'red' }}><em>Download Sample Data Format</em></span></a>
                                            <input className="form-control" type="file" id="file" onChange={handleFileChange} />
                                        </div>
                                       
                                      
                                        
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <button className="btn btn-primary">Upload Now</button>
                                  
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Index;