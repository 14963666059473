import React, { Fragment, useState, useReducer, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Table,
  Modal,
  Button,

} from "react-bootstrap";

import { Link } from "react-router-dom";
import axios from 'axios';
import {useNavigate } from "react-router-dom";
import {useDispatch } from 'react-redux';
import {saveAction } from '../../../../../../store/actions/AuthActions'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';


const examTopper = [{id:'111', color:'success', status:'Successful'}]

const init = false
const reducer = (state, active) =>{
	switch(active.type){
		case 'basicModal' :
			return { ...state, basicModal: !state.basicModal}
		case 'smallModal'	:
			return { ...state, smallModal: !state.smallModal}		
            case 'modalWithTooltip'	:
                return { ...state, modalWithTooltip: !state.modalWithTooltip}		
            case 'gridInsideModal'	:
                return { ...state, gridInsideModal: !state.gridInsideModal}
            case 'largeModal'	:
                return { ...state, largeModal: !state.largeModal}
		default :	
			return state;
       
	}	
}

const GradeIndex = () => {
  const dispatches = useDispatch();
  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_BASE_URL;  
  const [checked, setChecked] = useState(examTopper);
  const [unchecked, setUnChecked] = useState(true);
  const [state ,dispatch] = useReducer(reducer, init);
  const [error, setError] = useState(null);
  const [data, setData] =useState([]);
  const [ID, setID] =useState([]);
  const [fieldValue, setFieldValue]=useState();
  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  const userID = userDetails.userID;

  const [minscore,   setMinscore] =useState();
  const [maxscore,   setMaxscore] =useState();
  const [point, setPoint] =useState();
  const [grade, setGrade] =useState();
  const headers   = {'Content-Type': 'application/json', 'Authorization-Key': `${userDetails.apikey}`};



    useEffect(()=>{
        fetchGrade()
   },[])

   const fetchGrade = async () => {
    try {  
        
        
         axios.get(`${baseUrl}savegrade/${userID}`).then(response =>
         {
             
              if(response.data.statuscode===200)
              {
                setData(response.data.data);
              }
              else 
              {
                throw new Error('Network response was not ok');
              }
         }).catch((error) => {
          if (error.response) {
              //console.log(error.response);
              //console.log("server responded");
          } else if (error.request) {
             // console.log("network error");
          } else {
              //console.log(error);
          }
          });
       
      } catch (error) {
        throw new Error(`Error fetching items: ${error.message}`);
      }
  };

  function handleModalSubmit(e)
  {

      e.preventDefault();
      const path ="gradeupdate";
      const userData ={
                  id :ID,
                  minscore:e.target.minscore.value,
                  maxscore:e.target.maxscore.value,
                  grade:e.target.grade.value,
                  point:e.target.point.value,
                  userID:userDetails.userID,
                  createdBy:userDetails.email,
          } 
        dispatches(saveAction(userData,path,navigate));
        window.location.reload();
        fetchGrade()
        dispatch({type:'largeModal'})
  }
 function handleModalDelete(id)
 {
          axios.delete(`${baseUrl}deletegrade/${id}`,{headers}).then(response =>
          {       
            toast.success(response.data.error)
            dispatch({type:'smallModal'})
            fetchGrade()
           
          }).catch((error) => {
              if (error.response) {
                  //console.log(error.response.data.statuscode);
                  toast.error(error.response.data.error)
              } else if (error.request) {
                  //console.log("network error");
              } else {
                  //console.log(error);
              }
              });

 }

const handleNameMinChange = (e) => {
    setMinscore(e.target.value);
};
const handleMaxInputChange = (e) => {
    setMaxscore(e.target.value);
};
const handleGradeInputChange = (e) => {
    setGrade(e.target.value);
};

const handlePointInputChange = (e) => {
    setPoint(e.target.value);
};
  return (
    <Fragment>
      <ToastContainer position="top-right"></ToastContainer>
      <div className="container-fluid">
        <Row>
         <Col lg={2}></Col>
          <Col lg={9}>
            <Card>
              <Card.Header>
                <Card.Title>List of Grade System</Card.Title>
              </Card.Header>
              <Card.Body>
              <Table responsive striped security='1'> 
                  <thead>
                    <tr>
                   
                      <th><strong>NO.</strong></th>
                     
                      <th><strong>Minscore</strong></th>
                      <th><strong>Maxscore</strong></th>
                      <th><strong>Grade</strong></th>
                      <th><strong>Point</strong></th>
                    
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item, index)=>(
                      <tr key={index}>
                        
                        
						<td>{++index}</td>
                      
                        <td>{item.minscore}</td>
                        <td>{item.maxscore}</td>
                        <td>{item.grade}</td>
                        <td>{item.point}</td>
                        <td>
                          <div className="d-flex">
                            <Link
                              to={"#"}
                                onClick={() => dispatch({type:'largeModal'}, setID(item.id),setMinscore(item.minscore),setMaxscore(item.maxscore), setPoint(item.point),setGrade(item.grade))}
                                              className="shadow btn btn-primary btn-xs sharp me-1"
                            >
                              <i className="fas fa-pencil-alt"></i>
                            </Link>
                            <Link
                              onClick={() => dispatch({type:'smallModal'},setID(item.id), setGrade(item.grade))}
                              to={"#"}
                              className="shadow btn btn-danger btn-xs sharp"
                            >
                              <i className="fa fa-trash"></i>
                            </Link>
                          </div>
                        </td>
                      </tr>
                    ))}
                    
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>     
        </Row>
      </div>

           {/* <!-- Modal --> */} 
           
          
              <Modal className="fade" show={state.largeModal}  onHide={()=>dispatch({type:'largeModal'})}> 
              <form  onSubmit={handleModalSubmit}>
                <Modal.Header>
                  <Modal.Title>Edit Grade System</Modal.Title>
                  <Button variant="" className="btn-close" onClick={() => dispatch({type:'largeModal'})}></Button>
                </Modal.Header>
                <Modal.Body>
                  
                
                                  <div className="card-body">
                                      <div className="row">
                              
                                    
                                      
                                      <div className="col-sm-6 m-b30">
                                            <label className="form-label">Minscore</label>
                                            <input type="text" className="form-control" id="minscore" value={minscore} onChange={handleNameMinChange} name="minscore" placeholder="minscore" required />
                                        </div>
                                        <div className="col-sm-6 m-b30">
                                            <label className="form-label">Maxscore </label>
                                            <input type="text" className="form-control" id="maxscore" value={maxscore} onChange={handleMaxInputChange} name="maxscore" placeholder="maxscore" required />
                                        </div>
                                        <div className="col-sm-6 m-b30">
                                            <label className="form-label">Point </label>
                                            <input type="text" className="form-control" value={point} onChange={handlePointInputChange} id="point" name="point" placeholder="point" required />
                                        </div>
                                        <div className="col-sm-6 m-b30">
                                            <label className="form-label">Grade </label>
                                            <input type="text" className="form-control" value={grade} onChange={handleGradeInputChange} name="grade" id="grade" placeholder="grade" />
                                        </div>
                                        
                                          
                                          
                                      </div>
                                  </div>
                                  
                            
                

                </Modal.Body>
                <Modal.Footer>
                  <Button  variant="danger light" onClick={() => dispatch({type:'largeModal'})}>Close</Button>
                  <button className="btn btn-primary" type="submit">Update</button>
                  {/* <Button variant="primary">Update </Button> */}
                </Modal.Footer> 
                </form>
              </Modal>
       
         



         {/* Delete Modal */}
         <Modal className="fade bd-example-modal-sm" size="sm" show={state.smallModal} onHide={()=>dispatch({type:'smallModal'})}>
         {/* <form  onSubmit={handleModalDelete}> */}
			      <Modal.Header><Modal.Title>Delete Grade</Modal.Title>
                 <Button variant="" className="btn-close"	onClick={() => dispatch({type:'smallModal'})}></Button>
		       	</Modal.Header>
           <Modal.Body>
             <span style={{ color:'red' }}>Are you sure you want to delete {grade} information?</span>
          </Modal.Body>
			    <Modal.Footer>
            <Button  variant="danger light" onClick={() => dispatch({type:'smallModal'})}> Close </Button>
            <Button variant="danger" onClick={()=>handleModalDelete(ID)}>Delete</Button>
			    </Modal.Footer>
         {/* </form> */} 
        </Modal>
    </Fragment>
  );

};

export default GradeIndex;
