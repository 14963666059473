import React,{useState, useEffect} from 'react';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import axios from 'axios';
import { IMAGES } from '../../../../constant/theme';
import { Link, useNavigate } from "react-router-dom";
import { connect, useDispatch } from 'react-redux';

import {loadingToggleAction, uploadAction,uploadActions } from '../../../../../store/actions/AuthActions'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const Index = () => {
   const userDetails = JSON.parse(localStorage.getItem('userDetails'));
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const createdBy = userDetails.email;
   const userID = userDetails.userID;
   const [dataCountry, setDataCountry]=useState([]);
   const [error, setError] = useState(null);
   const [selectCountryValue, setselectCountryValue] = useState();
   const [fieldValue, setFieldValue]=useState();
   const baseUrl = process.env.REACT_APP_BASE_URL; 


   const [file, setFile] = useState([]);
   const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
     if (e.target.files) {
       setFile(e.target.files[0]);
       //console.log(file);
     }
   };
   function handleChange(e) 
   {
     e.preventDefault();

     if (!file) {
        return;
      }
      var countryId = selectCountryValue.value;
      var formData = new FormData();
      //countryId.append("countryId",countryId); 
      formData.append("fileImage",file); 
      const path ="stateupload";

      
      const userData = {
        countryId : countryId,
        userID: userID,
        createdBy:createdBy,
        path: path,
        file:file
       };

      dispatch(uploadActions(userData, navigate));

   }

   const selectCountryOnChange = (selectedOption) => 
    {
      setFieldValue('country', selectedOption.value);
      setselectCountryValue(selectedOption);
    }


    const fetchCountryZones = async () => {
        try {  
           
             axios.get(`${baseUrl}savescountryzone/${userID}`).then(response =>
             {
                //console.log(response.data.data);
                const customHeadings = response.data.data.map(item=>({
                    "value": item.id,
                    "label": item.country        
                 }))
                setDataCountry(customHeadings);
             }).catch((errors) => {
              if (errors.response) {
                  console.log(errors.response);
                  console.log("server responded");
              } else if (errors.request) {
                 // console.log("network error");
              } else {
                  //console.log(error);
              }
              });
           
          } catch (error) {
            throw new Error(`Error fetching items: ${error.message}`);
          }
      };
      
      useEffect(()=>{
        fetchCountryZones()
      },[])



    return(
        <>
            <ToastContainer position="top-center"></ToastContainer>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-1 col-lg-4">
                 
                    </div>
                    <div className="col-xl-9 col-lg-8">
                        <div className="card profile-card card-bx m-b30">
                            <div className="card-header">
                                <h6 className="title">Upload State </h6>
                            </div>
                            <form className="profile-form" onSubmit={handleChange}>						
                                <div className="card-body">
                                    <div className="row">
                            
                                    <div className="col-sm-8 m-b30">
                                            <label className="form-label">Country</label>
                                            <Select options={dataCountry}  className="custom-react-select" 
                                                            placeholder="Country"
                                                            value={selectCountryValue} onChange={selectCountryOnChange}
                                                            isSearchable={true} />      
                                      </div>
                                      
                                        <div className="col-sm-8 m-b30">
                                            <label className="form-label">Upload State</label><a href='/downloadables/state.csv' target="_blank"  ><span style={{ color:'red' }}><em>Download Sample Data Format</em></span></a>
                                            <input className="form-control" type="file" id="file" name="file" onChange={handleFileChange}/>
                                        </div>
                                       
                                      
                                        
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <button className="btn btn-primary">Upload Now</button>
                                  
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Index;