import React, { Fragment, useState, useReducer } from "react";
import PageTitle from "../../../../layouts/PageTitle";
import {
  Row,
  Card,
  Table,
  Modal,
  Button,

} from "react-bootstrap";
import { Link } from "react-router-dom";
import {useNavigate } from "react-router-dom";
import {useDispatch } from 'react-redux';
import {saveAction } from '../../../../../../src/store/actions/AuthActions'
import RoleIndex from '../../Reports/Views/Role'
const examTopper = [
  {id:'111', color:'success', status:'Successful'},
//   {id:'112', color:'danger', status:'Canceled'},
//   {id:'113', color:'warning', status:'Pending'},
];

const init = false
const reducer = (state, active) =>{
	switch(active.type){
		case 'basicModal' :
			return { ...state, basicModal: !state.basicModal}
		case 'contentModal'	:
			return { ...state, contentModal: !state.contentModal}
		case 'modalCentered'	:
			return { ...state, modalCentered: !state.modalCentered}
		case 'modalWithTooltip'	:
			return { ...state, modalWithTooltip: !state.modalWithTooltip}		
		case 'gridInsideModal'	:
			return { ...state, gridInsideModal: !state.gridInsideModal}
		case 'largeModal'	:
			return { ...state, largeModal: !state.largeModal}
		case 'smallModal'	:
			return { ...state, smallModal: !state.smallModal}		
		default :	
			return state;
	}	
}

const Index = () => {
  const dispatches = useDispatch();
  const navigate = useNavigate();
  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const [checked, setChecked] = useState(examTopper);
    const [unchecked, setUnChecked] = useState(true);
    const [state ,dispatch] = useReducer(reducer, init);
    const handleChecked = (id)=> {
          let temp = checked.map((data) => {
              if (id === data.id) {
                  return { ...data, inputchecked: !data.inputchecked };
              }
              return data;
          });
          setChecked(temp);
      };
      const handleCheckedAll = (value)=> {
          let temp = checked.map((data) => {          
              return { ...data, inputchecked: value };   
          });
          setChecked(temp);
          setUnChecked(!unchecked);
      };
  
   
    function handleSubmit(e) 
    {
      e.preventDefault();
      const path ="saveroles";
      const userData ={
                   name : e.target.role.value,
                   userID:userDetails.userID,
                   createdBy:userDetails.email,
         }
      dispatches(saveAction(userData,path,navigate));
    }



    return(
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-1 col-lg-4">
                 
                    </div>
                    <div className="col-xl-9 col-lg-8">
                        <div className="card profile-card card-bx m-b30">
                            <div className="card-header">
                                <h6 className="title">Create Roles </h6>
                            </div>
                            <form onSubmit={handleSubmit} className="profile-form">
                                <div className="card-body">
                                    <div className="row">
                        
                                      <div className="col-sm-6 m-b30">
                                            <label className="form-label">Role Name </label>
                                            <input type="text" className="form-control" id="role" name="role" placeholder="Admin" required />
                                        </div>
                                   </div>
                                <div className="card-footer">
                                    <button className="btn btn-primary">Add Role</button>
                                  
                                </div>
                                </div>                              
                            </form>

                        </div>
                    </div>

                  <RoleIndex/>
                    
                </div>

 {/* End of Record listing */}

    </div>
    
        </>
    )
}
export default Index;